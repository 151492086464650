import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { modelService } from "services";
import { TParams } from "types";
import { IModelParams } from "types/outfit";
import { END_POINT, QueryKeys } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

export const useModel = () => {
	const useGetModels = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [QueryKeys.MODELS, params.typeModel, params.limit],
			queryFn: ({ pageParam = params.page || 1 }) =>
				modelService.getModelServiceList({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});

		const models = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			models,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	const useGetYourModels = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [QueryKeys.YOUR_MODELS, params.typeModel, params.limit],
			queryFn: ({ pageParam = params.page || 1 }) =>
				modelService.getYourModelServiceList({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});

		const models = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			models,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	const useGetModelRandom = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [QueryKeys.MODELS_RANDOM, params.limit],
			queryFn: ({ pageParam = params.page || 1 }) =>
				modelService.getModelRandom({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});

		const models = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			models,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	const useGetGalleryList = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [
				QueryKeys.GALLERY_LIST,
				params.page,
				params.type,
				params.limit,
			],
			queryFn: ({ pageParam = params.page || 1 }) =>
				modelService.getModelServiceList(
					{ ...params, page: pageParam },
					END_POINT.GALLERY.GET_GALLERY,
				),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});

		const gallery = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			gallery,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	const deleteGalleryMutation = useMutation({
		mutationFn: (idGallery: string) => modelService.deleteGallery(idGallery),
		onSuccess: async ({ data }) => {
			console.log("Data", data);
			if (data?.statusCode === 200) {
				SnackbarUtils.success(data?.message || "Delete success");
				return;
			}
			SnackbarUtils.warning(data?.message || "Delete failed");
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed!");
		},
	});

	const { mutate: generateModel, isPending: isPendingCreate } = useMutation({
		mutationFn: (form: IModelParams) => modelService.createYourModels(form),
	});

	const deleteModelMutation = useMutation({
		mutationFn: (idModel: string) => modelService.deleteYourModel(idModel),
		onSuccess: async ({ data }) => {
			console.log("Data", data);
			if (data?.statusCode === 200) {
				SnackbarUtils.success(data?.message || "Delete success");
				return;
			}
			SnackbarUtils.warning(data?.message || "Delete failed");
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed!");
		},
	});

	return {
		useGetModels,
		useGetGalleryList,
		useGetModelRandom,
		useGetYourModels,
		deleteGalleryMutation,
		generateModel,
		isPendingCreate,
		deleteModelMutation,
	};
};
