/** @format */
/**
 * @description the interface of user authentication entity
 * @export
 * @interface IAppState
 */

import { EnumValueDataType } from "utils";

export interface IInforForgot {
	email: string;
	confirmCode: string;
	verifyCode: string;
}
export interface IAppState {
	loading: boolean;
	language: string;
	error: string | null;
	captcha?: string;
	showCaptcha?: boolean;
	isAuth: boolean;
	inforForgot: IInforForgot;
	authToken?: string;
}
export interface INofifyState {
	message: string;
	type: "success" | "info" | "warning" | "error";
	options: {
		position: {
			vertical: "top" | "bottom";
			horizontal: "left" | "center" | "right";
		};
		autoHideDuration: number;
		useI18n: boolean;
	};
}

export type IValueType = keyof typeof EnumValueDataType;

export enum ELanguage {
	KO = "ko",
	EN = "en",
}
export interface IKeyAuth {
	authToken: string;
	refreshToken: string;
	userId?: string;
	updateParent?: boolean;
}

export type IThemeColor = "main" | "light" | "dark";
export type IThemeMode = "default";

export type TPagination = {
	limit: number;
	page: number;
	total: number;
	totalPage: number;
};

export type TResponse<T> = {
	data: T;
	pagination?: TPagination;
	statusCode: number;
	message: string;
};

export type TParams = {
	page: number;
	limit: number;
	typeModel?: string;
	typeOutfit?: string;
	[x: string]: string | number | undefined;
};
