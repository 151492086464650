import { Box } from "@mui/material";
import React, { memo } from "react";
import TitleSection from "./TitleSection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
	img18,
	img17,
	img16,
	img15,
	img14,
	img13,
	img12,
	img11,
	img10,
	img9,
	img8,
	img7,
	img6,
	img5,
	img4,
	img3,
	img2,
	img1,
} from "assets";

const listCommu = [
	img18,
	img17,
	img16,
	img15,
	img14,
	img13,
	img12,
	img11,
	img10,
	img9,
	img8,
	img7,
	img6,
	img5,
	img4,
	img3,
	img2,
	img1,
];

const CommunityGeneration = () => {
	return (
		<Box className="wrap-content" mt={"70px"}>
			<TitleSection title="Community Generation" />
			<Box
				mt={"20px"}
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				gap={"20px"}
				flexWrap={"wrap"}>
				{listCommu.map((img, index) => {
					return (
						<Box
							borderRadius={"12px"}
							overflow={"hidden"}
							key={index}
							component={"div"}
							sx={{
								cursor: "pointer",
								":hover": {
									".img_box": {
										transform: "scale(1.1)",
									},
								},
							}}>
							<LazyLoadImage
								style={{
									display: "block",
									objectFit: "contain",
									width: "100%",
									transition: "all 0.25s ease-in",
								}}
								className="img_box"
								src={img}
								alt="model"
							/>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default memo(CommunityGeneration);
