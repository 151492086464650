/** @format */

import { useEffect, useState } from 'react';

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const useDevice = () => {
  const platForm = 'web-app';
  const [ipAddress, setIpAddress] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');

  useEffect(() => {
    (async () => {
      const res = await axios.get('https://api.ipify.org/?format=json');
      setIpAddress(res.data.ip);
    })();
  }, []);

  useEffect(() => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem('deviceId', deviceId as string);
    }
    setDeviceId(deviceId as string);
  }, []);

  return { ipAddress, platForm, deviceId };
};
