import React from "react";
import { Box } from "@mui/material";
import { TextCus, ButtonCus } from "components";
import { fontSize, mainColor } from "theme";
import { Icons } from "assets";

const ButtonGenerate = ({
	handelGenerate,
	currentStep,
	active,
	disabled,
}: {
	disabled?: boolean;
	handelGenerate: () => void;
	currentStep: number;
	active: boolean;
	action?: () => void;
}) => {
	return (
		<Box
			bgcolor={mainColor.white}
			width={"100%"}
			borderRadius={"12px"}
			position={"relative"}
			display={"flex"}
			justifyContent={"space-between"}
			padding={"15px"}
			sx={{
				height: { xs: "100px", xl: "145px" },
				gap: { xs: "10px", xl: "20px" },
				transition: "all 0.3s ease-in-out",
				border: active ? "1px solid #785BBA" : "0px solid #ffffff",
				boxShadow: active
					? "0 0 10px 0 rgba(33, 6, 93, 0.3)"
					: "0 0 0px 0 rgba(33, 6, 93, 0.3)",
			}}
			alignItems={"center"}
			flexDirection={"column"}>
			<TextCus
				fontWeight={"600"}
				color={mainColor.textSecondary}
				sx={{
					fontSize: {
						xs: "12px",
						xl: fontSize.xs,
					},
				}}>
				Click here to see the Result
			</TextCus>
			<ButtonCus
				onClick={handelGenerate}
				disabled={disabled}
				sx={{ gap: "20px", height: "56px !important" }}>
				{Icons.Sparkers()}
				<TextCus
					fontWeight={"700"}
					color={mainColor.white}
					fontSize={fontSize.sm}>
					Generate
				</TextCus>
			</ButtonCus>
			<Box
				bgcolor={"#5B3E9B"}
				width={"70px"}
				height={"24px"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				position={"absolute"}
				top={"20px"}
				sx={{ opacity: active ? 1 : 0, transition: "all 0.25s ease-in-out" }}
				left={active ? "-39px" : "-70px"}
				fontSize={"12px"}
				fontWeight={"700"}
				color={mainColor.white}
				borderRadius={"12px"}>
				Step {currentStep}
			</Box>
		</Box>
	);
};

export default ButtonGenerate;
