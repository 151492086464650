import React from "react";
import { Box } from "@mui/material";
import { kc } from "assets";
import { TextCus } from "components/TextCus";
import { useUser } from "hooks";
import { fontSize } from "theme";

const BoxCredit = () => {
	const { user } = useUser();
	return (
		<Box display={"flex"} alignItems={"center"} gap={"10px"}>
			<img width={20} src={kc} alt="kc" />
			<TextCus color={"#5271FF"} fontSize={fontSize.sm} fontWeight={"bold"}>
				{(user?.credits || 0) + ""}
			</TextCus>
		</Box>
	);
};

export default BoxCredit;
