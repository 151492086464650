import { useInfiniteQuery } from "@tanstack/react-query";
import { clothesService } from "services";
import { TParams } from "types";
import { QueryKeys } from "utils";

export const useClothes = () => {
	const useGetClothes = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [QueryKeys.GET_CLOTHES, params.page, params.typeOutfit],
			queryFn: ({ pageParam = params.page || 1 }) =>
				clothesService.getClothesExample({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});

		const clothes = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			clothes,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	const useGetYourClothes = (params: TParams) => {
		const {
			data,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		} = useInfiniteQuery({
			queryKey: [QueryKeys.YOUR_CLOTHES, params.page, params.typeOutfit],
			queryFn: ({ pageParam = params.page || 1 }) =>
				clothesService.getYourClothes({ ...params, page: pageParam }),
			getNextPageParam: (lastPage) => {
				const params = lastPage.pagination;
				if (params) {
					return params.page < params.totalPage ? params.page + 1 : undefined;
				}
				return undefined;
			},
			initialPageParam: params.page || 1,
		});

		const clothes = data?.pages.flatMap((page) => page.data) ?? [];
		return {
			clothes,
			fetchNextPage,
			hasNextPage,
			isFetchingNextPage,
			refetch,
			isLoading,
			isError,
		};
	};

	return {
		useGetClothes,
		useGetYourClothes,
	};
};
