import { Box } from "@mui/material";
import { logoLoad } from "assets";
import React from "react";
import { LoadingBar, TextCus } from "components";
import { mainColor } from "theme";

const BoxLoading = ({
	isStart,
	valueProcess,
	isLoadingHistory,
}: {
	isStart?: boolean;
	valueProcess: number;
	isLoadingHistory?: boolean;
}) => {
	return (
		<>
			<Box className="rotate-animation">
				<img src={logoLoad} width={"30px"} height={"40px"} alt="loading" />
			</Box>
			{!isLoadingHistory && (
				<>
					<TextCus
						fontWeight={"600"}
						fontSize={"14px"}
						color={mainColor.textSecondary}>
						{isStart
							? "Please wait your progress will start soon !"
							: "Generating your image, completing in 1 minutes"}
					</TextCus>
					<Box width={"200px"} maxWidth={"200px"}>
						<LoadingBar value={valueProcess} />
					</Box>
				</>
			)}
		</>
	);
};

export default BoxLoading;
