/** @format */

import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useKey } from 'hooks';
// import { authService } from 'services';

import { KEY_CONTEXT } from 'utils';
import { authService } from './authService';

const config = {
  baseURL: process.env.REACT_APP_BASE_URI,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

console.log(process.env.REACT_APP_BASE_URI);

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(
  async (req: any) => {
    const { getKey } = useKey();
    const token = getKey(KEY_CONTEXT.AUTH_TOKEN);

    if (token) {
      req.headers.Authorization = `Bearer ${token}`;
    }

    return req;
  },
  (err: any) => Promise.reject(err),
);

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (err: AxiosError) => {
    const originalRequest = err.config as InternalAxiosRequestConfig & {
      _retry?: boolean;
    };

    console.log('err.response?.status', err.response?.status, originalRequest._retry);

    if (err.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const { getKey,setKeySite } = useKey();

      const rfToken = getKey(KEY_CONTEXT.REFRESH_TOKEN);
      try {
        const newToken = (await authService.refreshToken(`${rfToken}`)) || '';
        const { access, refresh } = newToken.data;
        if (access && refresh) {
          setKeySite({ authToken: access, refreshToken: refresh});
          // Set new token in default headers
          axiosClient.defaults.headers.common['Authorization'] = `Bearer ${access}`;
          return axiosClient(originalRequest);
        }
      } catch (refreshError) {
        console.log('🚀 ~ refreshError:', refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(((err || {}).response || {}).data);
  },
);

export default axiosClient;
