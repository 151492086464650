import React from "react";
import { CloseOutlined } from "@mui/icons-material";
import { Box, Fade, IconButton, Modal } from "@mui/material";
import { TextCus } from "components/TextCus";
import { fontSize, mainColor } from "theme";
import { paymentService } from "services";
import { TFormCustom } from "types/payment";
import { useMutation } from "@tanstack/react-query";
import SnackbarUtils from "utils/SnackbarUtils";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customPlanSchema } from "utils";
import { InputOutLine } from "components/InputCus";
import { ButtonCus } from "components/ButtonCus";

interface IModalProfile {
	open: boolean;
	onClose: () => void;
}

const ModalSubmitInfo = ({ open, onClose }: IModalProfile) => {
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<TFormCustom>({
		resolver: yupResolver(customPlanSchema),
		defaultValues: {
			company: "",
			website: "",
			email: "",
			name: "",
			yourRole: "",
			firstName: "",
			lastName: "",
			message: "",
			describe: "",
		},
	});

	const requestContactMutation = useMutation({
		mutationFn: (form: TFormCustom) => paymentService.requestContact(form),
		onSuccess: async ({ data }) => {
			console.log("data", data);
			if (data?.statusCode === 200) {
				SnackbarUtils.success("Thank you!. We will respond to you!");
				onClose();
				return;
			}
			SnackbarUtils.warning(data?.message || "Request failed");
		},
		onError: () => {
			SnackbarUtils.warning("Request failed!");
		},
	});

	const onSubmit: SubmitHandler<TFormCustom> = (data) => {
		console.log(data);
		requestContactMutation.mutate(data);
		reset();
	};

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-center"}
					gap={"20px"}
					bgcolor={mainColor.white}
					flexDirection={"column"}
					paddingX={"30px"}
					paddingY={"20px"}
					sx={{
						maxWidth: "766px",
						maxHeight: "700px",
						overflowY: "auto",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"764px"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<TextCus fontWeight={"700"} fontSize={fontSize.sm}>
							Submit your information
						</TextCus>
						<IconButton onClick={onClose}>
							<CloseOutlined />
						</IconButton>
					</Box>
					<Box
						px={"15px"}
						component="form"
						width={"90%"}
						onSubmit={handleSubmit(onSubmit)}>
						<Box>
							<Controller
								name="company"
								control={control}
								render={({ field }) => (
									<InputOutLine
										label="Company"
										value={field.value}
										setValue={field.onChange}
										error={!!errors.company}
										errorMessage={errors?.company?.message}
										fullWidth
									/>
								)}
							/>
						</Box>
						<Box mt={1}>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<InputOutLine
										type="text"
										label="Email"
										{...field}
										value={field.value}
										setValue={field.onChange}
										fullWidth
										error={!!errors.email}
										errorMessage={errors?.email?.message}
									/>
								)}
							/>
						</Box>
						<Box mt={1}>
							<Controller
								name="website"
								control={control}
								render={({ field }) => (
									<InputOutLine
										label="Website"
										value={field.value + ""}
										setValue={field.onChange}
										error={!!errors.website}
										errorMessage={errors?.website?.message}
										fullWidth
									/>
								)}
							/>
						</Box>
						<Box mt={1}>
							<Controller
								name="yourRole"
								control={control}
								render={({ field }) => (
									<InputOutLine
										label="Role"
										value={field.value + ""}
										setValue={field.onChange}
										error={!!errors.yourRole}
										errorMessage={errors?.yourRole?.message}
										fullWidth
									/>
								)}
							/>
						</Box>
						<Box
							display={"flex"}
							alignItems={"center"}
							gap={"10px"}
							width={"100%"}
							justifyContent={"space-between"}>
							<Box mt={1} flex={1}>
								<Controller
									name="firstName"
									control={control}
									render={({ field }) => (
										<InputOutLine
											label="First name"
											value={field.value}
											setValue={field.onChange}
											error={!!errors.firstName}
											errorMessage={errors?.firstName?.message}
											fullWidth
										/>
									)}
								/>
							</Box>
							<Box mt={1} flex={1}>
								<Controller
									name="lastName"
									control={control}
									render={({ field }) => (
										<InputOutLine
											label="Last name"
											value={field.value + ""}
											setValue={field.onChange}
											error={!!errors.lastName}
											errorMessage={errors?.lastName?.message}
											fullWidth
										/>
									)}
								/>
							</Box>
						</Box>

						<Box mt={1}>
							<Controller
								name="message"
								control={control}
								render={({ field }) => (
									<InputOutLine
										label="Message"
										value={field.value}
										setValue={field.onChange}
										error={!!errors.message}
										errorMessage={errors?.message?.message}
										fullWidth
									/>
								)}
							/>
						</Box>
						<Box
							mt={3}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}>
							<ButtonCus
								title="Register"
								LabelProps={{ variant: "bodyBold" }}
								useI18n
								sx={{ width: "100%" }}
								variant={"text"}
								type="submit"
								isLoading={requestContactMutation.isPending}
							/>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalSubmitInfo;
