/** @format */

import { Box } from "@mui/material";
import { ButtonCus } from "components/ButtonCus";
import { TextCus } from "components/TextCus";
import React, { useRef, useState } from "react";
import { fontSize, mainColor } from "theme";
import { InputOutLine } from "components/InputCus";
import { logo } from "assets";
import { Controller, useForm, Resolver, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dynamicSchema } from "utils";
import {
	EModelAuth,
	ErrorOtpForm,
	ErrorOtpMessage,
	ForgotForm,
	OTPForm,
	TypeSendMailOtp,
} from "types";
import { useLayout, useTimer, useUser } from "hooks";
import { useMutation } from "@tanstack/react-query";
import { authService, userService } from "services";
import SnackbarUtils from "utils/SnackbarUtils";

const ForgotPass = () => {
	const [isOtpStep, setIsOtpStep] = useState(false);
	const refEmail = useRef({ email: "" }).current;
	const { setNewPassViaUid } = useUser();
	const { seconds, isActive, startTimer } = useTimer(60);
	const { setOpenModalAuth } = useLayout();

	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isSubmitting },
	} = useForm<ForgotForm | OTPForm>({
		resolver: yupResolver(dynamicSchema(isOtpStep)) as unknown as Resolver<
			ForgotForm | OTPForm
		>,
		defaultValues: {
			email: "",
			otp: "",
		},
	});

	const sendOtpMutation = useMutation({
		mutationFn: (formData: ForgotForm) =>
			userService.getOtpViaMail(
				formData.email,
				TypeSendMailOtp.FOR_GOT_PASS_WORD,
			),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				SnackbarUtils.success(`Otp has been sent to email successfully`);
				setIsOtpStep(true);
				startTimer();
				return;
			}
			SnackbarUtils.warning(data?.message || "Failed to send otp");
		},
		onError: () => {
			SnackbarUtils.warning("Change failed!");
		},
	});

	const verifyOtpMutation = useMutation({
		mutationFn: (formData: OTPForm) => authService.verifyOtp(formData.otp),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				setNewPassViaUid(data?.data?.uid);
				setOpenModalAuth(EModelAuth.RESET_PASS);
				setIsOtpStep(false);
				return;
			}
			SnackbarUtils.warning(data?.message || "Failed to send otp");
		},
		onError: () => {
			SnackbarUtils.warning("Change failed!");
		},
	});

	const onSubmit: SubmitHandler<ForgotForm | OTPForm> = (data) => {
		if (!isOtpStep) {
			refEmail.email = data.email;
			sendOtpMutation.mutate(data);
			reset();
		} else {
			verifyOtpMutation.mutate(data as OTPForm);
		}
	};

	return (
		<Box
			flex={1}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			gap={"100px"}
			flexDirection={"column"}
			width={"100%"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				width={"90%"}
				px={"15px"}
				flexDirection={"column"}
				gap={"20px"}>
				<img src={logo} width={"100px"} />
			</Box>

			<Box
				mt={2}
				flex={0.5}
				width={"90%"}
				px={"20px"}
				component="form"
				onSubmit={handleSubmit(onSubmit)}>
				<TextCus fontSize={"20px"} mb={2} fontWeight={"700"} color={"#333333"}>
					Forgot password
				</TextCus>

				{!isOtpStep ? (
					<Controller
						name="email"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<InputOutLine
								label="Enter your email"
								value={`${field.value}`}
								setValue={field.onChange}
								error={!!errors.email}
								errorMessage={errors?.email?.message}
								fullWidth
								sx={{
									mt: 5,
									mb: 2.5,
								}}
							/>
						)}
					/>
				) : (
					<Controller
						name="otp"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<InputOutLine
								label="Enter OTP"
								value={`${field.value}`}
								setValue={field.onChange}
								error={!!(errors as ErrorOtpForm)?.otp}
								errorMessage={(errors as ErrorOtpMessage)?.otp?.message}
								fullWidth
								sx={{
									mt: 5,
									mb: 2.5,
								}}
							/>
						)}
					/>
				)}
				<ButtonCus
					sx={{
						mt: 5,
						backgroundColor: isValid
							? mainColor.secondary
							: mainColor.secondary,
					}}
					fullWidth
					type="submit"
					isLoading={sendOtpMutation.isPending || verifyOtpMutation.isPending}
					disabled={!isValid || isSubmitting}>
					<TextCus
						color={mainColor.white}
						fontSize={fontSize.sm}
						fontWeight={"600"}>
						Verify
					</TextCus>
				</ButtonCus>
			</Box>
			{isOtpStep && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}>
					<TextCus color={mainColor.textMain}>Didn’t get any email?</TextCus>
					<Box
						display={"flex"}
						ml={1}
						gap={"3px"}
						sx={{ cursor: "pointer" }}
						onClick={() => {
							if (!isActive) {
								sendOtpMutation.mutate({
									email: localStorage.getItem("email") + "",
								});
								startTimer();
							}
						}}>
						<TextCus useI18n variant="body1" color={mainColor.secondary}>
							Resend
						</TextCus>
						{isActive ? (
							<TextCus useI18n variant="body1" color={mainColor.textMain}>
								{` (${seconds}s)`}
							</TextCus>
						) : (
							""
						)}
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default ForgotPass;
