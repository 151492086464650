/** @format */

export const END_POINT = {
	AUTH: {
		LOGIN: "/auth/login",
		REGISTER: "/auth/register",
		REFRESH_TOKEN: "/auth/refresh-token",
		CHANGE_PASSWORD: "/auth/change-password",
		LOGIN_SOCIAL: "/auth/login-social",
		VERIFY_OTP: "/auth/verify-otp",
		VERIFY_EMAIL: "/auth/verify-email",
	},
	USER: {
		GET_PROFILE: "/user/me",
		LOGOUT: "/user/logout",
		UPDATE_PROFILE: "/user/profile",
		UPDATE_PASS: "/auth/update-password",
	},
	OTP: {
		SEND_OTP: "/send-mail/otp",
	},
	MODEL: {
		GET_EXAMPLE: "/model-body/example",
		GET_RANDOM: "/model-body/random",
		GET_YOUR_MODEL: "/model-body/your-model",
		CREATE_YOUR_MODEL: "/model-body/create-your-model",
		DELETE_YOUR_MODEL: "/model-body/user/delete",
	},
	CLOTHES: {
		GET_EXAMPLE: "/clothes/example",
		YOUR_ITEMS: "/clothes/your-clothes",
	},
	AI_TOOLS: {
		OUTFIT_TOOLS: "/ai-tools/outfit",
		FACE_SWAP: "/ai-tools/face-swap",
	},
	GALLERY: {
		GET_GALLERY: "/gallery/user",
		DELETE_GALLERY: "/gallery/delete-your-gallery",
		DETAIL_GALLERY: "/gallery/details?id=",
	},
	PAYMENT: {
		ORDER: "/payments/paypal/orders",
		APPROVE: "/payments/paypal/approve",
		PAYMENT_PAYPAL: "/payments",
		PLAN: "/plan",
		DETAIL_PLAN: "/plan/detail?id=",
		TRANSACTIONS: "/transaction-plans/user",
		CONTACT_US: "/contact-us",
	},

	// ============== END_POINT CHAT ====================
	CHAT: {
		CREATE_CHARACTER: "/api/v1/chatbot/did/import/image/",
		HISTORIES: "/api/v1/chatbot/chat/histories/",
		CREATE_NEW_STREAM: "/api/v1/chatbot/did/create-new-stream/",
		START_STREAM: "/api/v1/chatbot/did/start-stream/", //stream_id
		CREATE_CLIP_STREAM: "/api/v1/chatbot/did/create-clip-stream/", //stream_id
		SUBMIT_NETWORK: "/api/v1/chatbot/did/submit-network-information/", //stream_id
	},
};
