/** @format */

import { FC } from "react";

import { MainLayout, ProcessLayout } from "components";
import { Dashboard } from "pages/admin/dashboard";
import { Models } from "pages/models";
import { YourGeneration } from "pages/yourGeneration";
import { StyleFit } from "pages/admin/stylefit";
import { Plan } from "pages/plan";
import { ROUTES } from "utils";
import { PlanInfoPayment } from "pages/plan/inforPayment";
import { History } from "pages/admin/history";
import { HistoryLayout } from "components/Layouts";
import { Transaction } from "pages/transaction";

type TComponent = FC<{
	children: string | JSX.Element | JSX.Element[] | JSX.Element;
}>;

export interface RouterType {
	path: string;
	component: FC;
	layout: TComponent;
}

const publicRoutes: RouterType[] = [];

const privateRoutes: RouterType[] = [
	{
		path: ROUTES.APP_ROOT.DASHBOARD,
		component: Dashboard,
		layout: MainLayout as TComponent,
	},
	{
		path: ROUTES.APP_ROOT.STYLE_FIT,
		component: StyleFit,
		layout: ProcessLayout as TComponent,
	},
	{
		path: ROUTES.APP_ROOT.HISTORY,
		component: History,
		layout: HistoryLayout as TComponent,
	},
	{
		path: ROUTES.APP_ROOT.MODELS,
		component: Models,
		layout: MainLayout as TComponent,
	},
	{
		path: ROUTES.APP_ROOT.YOUR_GENERATION,
		component: YourGeneration,
		layout: MainLayout as TComponent,
	},
	{
		path: ROUTES.APP_ROOT.PLAN,
		component: Plan,
		layout: MainLayout as TComponent,
	},
	{
		path: ROUTES.APP_ROOT.INFO_PAYMENT,
		component: PlanInfoPayment,
		layout: MainLayout as TComponent,
	},
	{
		path: ROUTES.APP_ROOT.TRANSACTION,
		component: Transaction,
		layout: MainLayout as TComponent,
	},
];

export { privateRoutes, publicRoutes };
