/** @format */
import React, { createContext } from "react";
import { IUserActionsType } from "types";
const UserStateContext = createContext<any>(null);
const UserDispatchContext = createContext<any>(null);

function userReducer(state: any, action: any) {
  // console.log("🚀 ~ userReducer ~ action:", action);
  switch (action.type) {
    case IUserActionsType.UPDATE_PARAMS:
      // console.log(action?.payload);
      // console.log("====================================");
      return {
        ...state,
        ...action?.payload,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }: any) {
  // console.log("====================================");
  // console.log(token);

  const [state, dispatch] = React.useReducer(userReducer, {
    profile: {},
  });
  // console.log("=============UserProvider=======================", state);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    return new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, dispatchActions };

// ###########################################################
function dispatchActions(
  { type, payload }: any,
  dispatch: React.Dispatch<any>
) {
  console.log("🚀 ~ dispatchActions ~ type, payload:", type, payload);
  return dispatch({
    type,
    payload,
  });
}
