import React, { useState } from "react";
import { Box } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Icons } from "assets";
import { ButtonCus, TextCus } from "components";
import { fontSize, mainColor } from "theme";
import { useNavigate } from "react-router-dom";
import { useLayout, useUser } from "hooks";
import { EModelAuth } from "types";

type TInfoFeature = {
	title: string;
	desc: string;
	link: string;
	img: string;
	image: string;
};

const BoxStart = ({ item }: { item: TInfoFeature }) => {
	const navigate = useNavigate();
	const { setOpenModalAuth } = useLayout();
	const { isLogged } = useUser();
	const [isHovered, setIsHovered] = useState(false);

	return (
		<Box
			bgcolor={"#ffffff69"}
			borderRadius={"12px"}
			className="transition_cus"
			overflow={"hidden"}
			padding={"5px"}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			sx={{
				maxWidth: {
					xs: "250px",
					xl: "300px",
				},
				cursor: "pointer",
				":hover": {
					background: mainColor.bgLinear,
					".overlay_box": {
						opacity: 1,
						top: 0,
						transition: "all 0.25s ease-in-out",
					},
				},
			}}>
			<Box borderRadius={"10px"} overflow={"hidden"} position={"relative"}>
				<LazyLoadImage
					style={{
						display: "block",
						objectFit: "contain",
						width: "100%",
						transition: "all 0.25s ease-in-out",
					}}
					src={isHovered ? item.img : item.image}
					alt="banner"
				/>
				<Box
					bgcolor={"rgba(255, 255, 255, 0.7)"}
					position={"absolute"}
					bottom={0}
					padding={"15px"}
					borderRadius={" 0 0 10px 10px"}
					width={"100%"}
					height={"62px"}
					display={"flex"}
					justifyContent={"start"}
					flexDirection={"column"}>
					<TextCus
						fontWeight={"600"}
						fontSize={fontSize.xs}
						color={mainColor.secondary}>
						{item.title}
					</TextCus>
					<TextCus
						fontWeight={"500"}
						fontSize={"12px"}
						color={mainColor.secondary}>
						{item.desc}
					</TextCus>
				</Box>
				{/* Box hover */}
				<Box
					position={"absolute"}
					className="overlay_box"
					sx={{ opacity: 0 }}
					top={"10px"}
					bgcolor={"rgb(33 6 93 / 26%)"}
					width={"100%"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					height={"100%"}>
					<ButtonCus
						sx={{ gap: "20px" }}
						onClick={() => {
							if (item.title !== "Style Fit" || !isLogged) {
								return setOpenModalAuth(EModelAuth.LOGIN);
							}
							return navigate(item.link);
						}}>
						<TextCus fontWeight={"600"} fontSize={fontSize.xs}>
							{item.title === "Style Fit" ? "Let's start" : "Coming soon"}
						</TextCus>
						{Icons.Start()}
					</ButtonCus>
				</Box>
			</Box>
		</Box>
	);
};

export default BoxStart;
