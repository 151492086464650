import { Box } from "@mui/material";
import React from "react";
import { Banner, PresetModels, YourModels } from "./components";

const Models = () => {
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			gap={"30px"}
			paddingBottom={"100px"}>
			<Banner />
			<YourModels />
			<PresetModels />
		</Box>
	);
};

export default Models;
