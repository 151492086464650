/** @format */

import React, { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { iconMore, Icons } from "assets";
import { Box, IconButton, Popover } from "@mui/material";
import { TextCus } from "components";
import { fontSize, mainColor } from "theme";
import { IGallery } from "types/outfit";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";

const BoxTemplate = ({
	item,
	onDelete,
}: {
	item?: IGallery;
	onDelete: (id: string) => void;
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const navigate = useNavigate();
	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			setAnchorEl(event.currentTarget);
		},
		[],
	);
	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const arrOption = useMemo(
		() => [
			{
				icon: Icons.Edit(),
				text: "Edit",
				action: () => {
					navigate(`${APP_ROOT.HISTORY}?id=${item?.id}`);
					handleClose();
				},
			},
			{
				icon: Icons.Delete(),
				text: "Delete",
				action: () => {
					onDelete(item?.id + "");
					handleClose();
				},
			},
		],
		[],
	);

	return (
		<Box
			component={"div"}
			position={"relative"}
			width={"calc((100% - 40px) / 3)"}
			sx={{
				cursor: "pointer",
				":hover": {
					".icon_option": {
						opacity: 1,
					},
				},
			}}
			borderRadius={"12px"}
			display={"flex"}
			flexDirection={"column"}
			gap={"10px"}>
			<Box
				position={"relative"}
				maxHeight={"212px"}
				width={"100%"}
				height={"212px"}
				borderRadius={"20px"}
				overflow={"hidden"}
				display={"flex"}
				alignItems={"flex-start"}
				bgcolor={"#ccc"}
				onClick={() => navigate(`${APP_ROOT.HISTORY}?id=${item?.id}`)}
				justifyContent={"center"}>
				<LazyLoadImage
					style={{
						objectFit: "cover",
						display: "block",
						width: "100%",
					}}
					src={item?.image}
					alt="image project"
				/>
				<Box
					className="icon_option transition_cus"
					position={"absolute"}
					sx={{ opacity: 0 }}
					zIndex={9}
					top={"5px"}
					right={"5px"}>
					<IconButton
						onClick={handleClick}
						sx={{ width: "25px", height: "25px" }}>
						<img src={iconMore} alt="more" width={"25px"} />
					</IconButton>
				</Box>
			</Box>

			<Box
				paddingLeft={"10px"}
				onClick={() => navigate(`${APP_ROOT.HISTORY}?id=${item?.id}`)}>
				<TextCus
					color={mainColor.textMain}
					fontSize={fontSize.sm}
					fontWeight={"600"}>
					{!item?.description?.length ? item?.id : item?.description}
				</TextCus>
				<TextCus
					fontSize={"12px"}
					fontWeight={"500"}
					color={mainColor.textGray}>
					{dayjs(item?.createdAt).format("DD/MM/YYYY hh:mm:ss") || ""}
				</TextCus>
			</Box>

			{/* ========== POPUP OPTION ============ */}
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				sx={{
					top: "-25px",
					overflow: "hidden",
					borderRadius: "20px",
					left: "-120px",
				}}>
				<Box
					width={"120px"}
					padding={"15px"}
					display={"flex"}
					overflow={"hidden"}
					bgcolor={mainColor.primary}
					flexDirection={"column"}
					gap={"15px"}>
					{arrOption.map((item) => (
						<Box
							key={item.text}
							display={"flex"}
							alignItems={"center"}
							sx={{ cursor: "pointer" }}
							component={"div"}
							onClick={item.action}
							justifyContent={"start"}
							gap={"15px"}>
							{item.icon}
							<TextCus useI18n>{item.text}</TextCus>
						</Box>
					))}
				</Box>
			</Popover>
		</Box>
	);
};

export default BoxTemplate;
