/** @format */

import React from "react";
import { Icons } from "assets";
import { Box, FormControl, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styled from "@emotion/styled";
import { TextCus } from "components/TextCus";

interface CommonSelectProps {
	options: { value: string; name: string }[];
	value: string;
	onChange: (event: SelectChangeEvent<any>) => void;
	width?: string;
	label?: string;
}

const CustomSelect = styled(Select)(() => ({
	backgroundColor: "#F5F5F5",
	borderRadius: "10px",
	height: "35px",
	"& .MuiInputBase-root.MuiInput-root.MuiInput-underline": {
		"::before": {
			content: "none",
		},
		"::after": {
			content: "none",
		},
	},
	"& .MuiFormLabel-root.MuiInputLabel-root": {
		display: "none !important",
	},
	"& .MuiSelect-icon": {
		color: "#3F51B5",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent",
	},
	padding: "10px 16px",
}));

const SelectCus = ({
	options,
	value,
	onChange,
	width,
	label,
	...selectProps
}: CommonSelectProps) => {
	return (
		<FormControl fullWidth variant="outlined">
			{label && (
				<Box display={"flex"} alignItems={"start"} mb={"5px"}>
					<TextCus fontSize={"13px"} fontWeight={"400"}>
						{label}
					</TextCus>
					<TextCus color={"red"} ml={"2px"}>
						*
					</TextCus>
				</Box>
			)}
			<CustomSelect
				sx={{ width: width ?? "100%" }}
				IconComponent={() => (
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Icons.ArrowSelect />
					</Box>
				)}
				value={value}
				variant="standard"
				onChange={onChange}
				{...selectProps}>
				{options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.name}
					</MenuItem>
				))}
			</CustomSelect>
		</FormControl>
	);
};

export default SelectCus;
