/** @format */

import { FormLogin, FormLoginSocial, FormRegister } from "types";
import { END_POINT } from "utils";
import axiosClient from "./axios";

export const authService = {
	refreshToken: async (rfToken: string) => {
		return await postRequest(END_POINT.AUTH.REFRESH_TOKEN, {
			refresh: rfToken,
		});
	},
	login: async (formData: FormLogin) => {
		return await postRequest(END_POINT.AUTH.LOGIN, formData);
	},
	loginGoogle: async (idToken: FormLoginSocial) => {
		return await postRequest(END_POINT.AUTH.LOGIN_SOCIAL, idToken);
	},
	register: async (formData: FormRegister) => {
		return await postRequest(END_POINT.AUTH.REGISTER, formData);
	},
	verifyEmail: async (otp: string) => {
		return await postRequest(END_POINT.AUTH.VERIFY_EMAIL, {
			otp,
		});
	},
	verifyOtp: async (otp: string) => {
		return await postRequest(END_POINT.AUTH.VERIFY_OTP, {
			otp,
		});
	},
	changePassword: async (formData: { uid: string; newPassword: string }) => {
		return await postRequest(END_POINT.AUTH.CHANGE_PASSWORD, {
			uid: formData.uid,
			newPassword: formData.newPassword,
		});
	},
};

const postRequest = (url: string, param: any) => axiosClient.post(url, param);
