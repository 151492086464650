import { Box } from "@mui/material";
import { iconHair, iconMe, Icons } from "assets";
import { ButtonCus, TextCus } from "components";
import React, { useEffect, useState } from "react";
import { fontSize, mainColor } from "theme";
import BoxLoading from "./BoxLoading";
import { IFinalOutfitResult } from "types/outfit";
import { LazyLoadImage } from "react-lazy-load-image-component";
interface ProcessResultProps {
	initialImage?: string;
	isLoading?: boolean;
	valueProcess?: number;
	isStart?: boolean;
	handleSwitchItem: () => void;
	processing?: IFinalOutfitResult | null | string;
	imgStyle?: string;
	isLoadingHistory?: boolean;
}
const BoxResult = ({
	initialImage,
	isLoading,
	valueProcess,
	isStart,
	handleSwitchItem,
	// processing,
	imgStyle,
	isLoadingHistory,
}: ProcessResultProps) => {
	const [previewUrl, setPreviewUrl] = useState<string | null>();
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);

	useEffect(() => {
		if (initialImage) {
			typeof initialImage === "string" && setPreviewUrl(initialImage);
		}
	}, [initialImage]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	// useEffect(() => {
	// 	if (!processing) setPreviewUrl(null);
	// }, [processing]);

	return (
		<Box
			bgcolor={mainColor.white}
			width={"100%"}
			height={"95%"}
			display={"flex"}
			flexDirection={"column"}
			alignItems={"center"}
			justifyContent={"center"}
			padding={"15px"}
			position={"relative"}
			borderRadius={"12px"}>
			<Box
				bgcolor={mainColor.white}
				width={"100%"}
				zIndex={99}
				height={"100%"}
				position={"absolute"}
				display={"flex"}
				flexDirection={"column"}
				alignItems={"center"}
				justifyContent={"center"}
				padding={"15px"}
				borderRadius={"12px"}>
				<TextCus
					fontWeight={"600"}
					fontSize={"14px"}
					marginBottom={"20px"}
					color={mainColor.textSecondary}>
					Your Style Fit
				</TextCus>

				<Box
					height="calc(100% - 100px)"
					width={"100%"}
					flex={1}
					borderRadius={"12px"}
					bgcolor={"#f2f2f2"}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					position={"relative"}
					gap={"30px"}>
					{previewUrl ? (
						<Box
							flex={1}
							display={"flex"}
							alignItems={"center"}
							flexDirection={"column"}
							justifyContent={"space-around"}
							maxWidth={"100%"}
							height={"100%"}>
							<Box
								margin={"0 auto"}
								width={"100%"}
								overflow={"hidden"}
								height={"calc(100% - 100px)"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}>
								<img
									src={previewUrl + ""}
									alt="Preview"
									style={{
										display: "block",
										width: "80%",
										margin: "0 auto",
										height: "100%",
										opacity: isPreviewVisible ? 1 : 0,
										transition: "opacity 0.5s ease-in-out",
										borderRadius: "10px",
										objectFit: "cover",
									}}
								/>
							</Box>

							{isLoading && (
								<Box
									position={"absolute"}
									top={0}
									left={0}
									zIndex={9}
									gap={"15px"}
									borderRadius={"12px"}
									display={"flex"}
									flexDirection={"column"}
									alignItems={"center"}
									justifyContent={"center"}
									width={"100%"}
									height={"100%"}
									bgcolor={"#e9e0e085"}>
									<BoxLoading
										valueProcess={valueProcess || 0}
										isStart={isStart}
										isLoadingHistory={isLoadingHistory}
									/>
								</Box>
							)}
							<Box
								display={"flex"}
								alignItems={"center"}
								width={"100%"}
								gap={"15px"}
								padding={"0 15px"}
								justifyContent={"center"}>
								<ButtonCus sx={{ padding: "0px !important", flex: 1 }}>
									<Box
										bgcolor={"white"}
										width={"98%"}
										borderRadius={"10px"}
										height={"90%"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}>
										<Box
											display={"flex"}
											alignItems={"center"}
											gap={"15px"}
											className="text_linear"
											fontSize={fontSize.xs}
											fontWeight={"600"}>
											<img src={iconMe} alt="me" />
											Gen Me
										</Box>
									</Box>
								</ButtonCus>
								<ButtonCus sx={{ padding: "0px !important", flex: 1 }}>
									<Box
										bgcolor={"white"}
										width={"98%"}
										borderRadius={"10px"}
										height={"90%"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}>
										<Box
											display={"flex"}
											alignItems={"center"}
											gap={"15px"}
											className="text_linear"
											fontSize={fontSize.xs}
											fontWeight={"600"}>
											<img src={iconHair} alt="me" />
											Style Hair
										</Box>
									</Box>
								</ButtonCus>
								<ButtonCus
									sx={{
										flex: 1,
										background:
											"linear-gradient(90deg, rgba(254, 85, 244, 0.8), rgba(109, 219, 254, 0.8)) !important",
										gap: "15px",
									}}
									onClick={handleSwitchItem}>
									{Icons.Res()}
									<TextCus fontSize={fontSize.xs} fontWeight={"600"}>
										Switch Item
									</TextCus>
								</ButtonCus>
							</Box>
							<Box
								position={"absolute"}
								top={"10px"}
								right={"10px"}
								component={"a"}
								href={previewUrl + ""}
								download={"downloaded_image.jpg"}
								bgcolor={mainColor.white}
								onClick={(e) => e.stopPropagation()}
								height={"36px"}
								width={"36px"}
								borderRadius={"10px"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
								sx={{
									cursor: "pointer",
								}}>
								<Icons.Download />
							</Box>
						</Box>
					) : (
						<Box
							width={"100%"}
							flex={1}
							borderRadius={"12px"}
							bgcolor={"#f2f2f2"}
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							gap={"30px"}>
							{!isLoading ? (
								<>
									{!imgStyle ? (
										Icons.Sparkers_2()
									) : (
										<LazyLoadImage
											src={imgStyle}
											style={{ display: "block" }}
											alt="Upload"
										/>
									)}
									<TextCus
										fontWeight={"600"}
										fontSize={"14px"}
										color={mainColor.textSecondary}>
										Your result will be shown here
									</TextCus>
								</>
							) : (
								<BoxLoading
									valueProcess={valueProcess || 0}
									isLoadingHistory={isLoadingHistory}
									isStart={isStart}
								/>
							)}
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default BoxResult;
