/** @format */
import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
	BoxPhoto,
	ButtonGenerate,
	BoxResult,
	ButtonAction,
} from "./components";
import { StepperCus } from "components";
import {
	EDropType,
	EnumStatusOutfit,
	IFinalOutfitResult,
	IOutfitParams,
} from "types/outfit";
import {
	useAITools,
	useModel,
	useOutfitStore,
	useProfile,
	useUser,
} from "hooks";
import {
	dressFull,
	dressKnee,
	dressMid,
	Icons,
	lowerFull,
	lowerKnee,
	lowerMid,
	upper,
} from "assets";
import { useSocket } from "contexts";
import SnackbarUtils from "utils/SnackbarUtils";

const StyleFit = () => {
	const {
		modelImage,
		imageClothes,
		currentStep,
		selectedFileClothes,
		selectedFileModel,
		selectedFileGallery,
		setCurrentStep,
		valueCategory,
		valueSize,
		denoiseSteps,
		setIsLoadingProcess,
		isLoadingProcess,
		reset,
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
	} = useOutfitStore();
	const { user } = useUser();
	const { getProfile } = useProfile();
	const { generateOutfit } = useAITools();
	const { socket } = useSocket();

	const [processing, setProcessing] = useState<IFinalOutfitResult | null>(null);
	const { useGetGalleryList } = useModel();
	const { refetch } = useGetGalleryList({
		page: 1,
		limit: 4,
		type: "OUTFIT",
	});

	const arrButton = [
		{
			icon: Icons.Reset(),
			title: "Reset",
			action: () => {
				reset();
				setProcessing(null);
			},
		},
		{
			icon: Icons.Undo(),
			title: "Prev",
			action: () => currentStep > 0 && setCurrentStep(currentStep - 1),
		},
		{
			icon: Icons.Redo(),
			title: "Next",
			action: () => currentStep < 2 && setCurrentStep(currentStep + 1),
		},
	];

	const handelGenerate = useCallback(async () => {
		setCurrentStep(2);
		setProcessing({
			...processing,
			currentStep: 0,
			totalStep: 0,
			status: EnumStatusOutfit.STARTED,
		});
		setIsLoadingProcess(true);

		const formData = new FormData();
		formData.append(
			"garmentCategory",
			valueCategory === "UPPER"
				? "upper_body"
				: valueCategory === "LOWER"
				? "lower_body"
				: "dresses",
		);
		formData.append("garmentLength", valueSize);
		formData.append("denoiseSteps", denoiseSteps + "");

		if (typeof selectedFileClothes === "string") {
			formData.append("clothesId", selectedFileClothes);
		} else {
			formData.append("fileClothes", selectedFileClothes as File);
		}

		if (typeof selectedFileModel === "string") {
			formData.append("modelBodyId", selectedFileModel);
		} else if (typeof selectedFileGallery === "string") {
			formData.append("galleryId", selectedFileGallery);
		} else {
			formData.append("fileModel", selectedFileModel as File);
		}

		generateOutfit(formData as IOutfitParams, {
			onSuccess: async (res) => {
				if (res?.data?.statusCode === 400) {
					setIsLoadingProcess(false);
					SnackbarUtils.error(res.data?.message || "Please try again!");
					setProcessing({
						...processing,
						status: EnumStatusOutfit.FAILURE,
					});
				}
			},
			onError: () => {
				SnackbarUtils.error("Please try again!");
				setIsLoadingProcess(false);
				setProcessing({
					...processing,
					status: EnumStatusOutfit.FAILURE,
				});
			},
		});
	}, [
		selectedFileModel,
		selectedFileClothes,
		selectedFileGallery,
		valueCategory,
		valueSize,
		denoiseSteps,
	]);

	const handleActionStatus = useCallback(() => {
		setIsLoadingProcess(false);
		setCurrentStep(3);
		getProfile();
		refetch();
	}, []);

	const handleSwitchItem = useCallback(() => {
		setCurrentStep(0);
		setSelectedFileModel(null);
		setModelImage(processing?.url as string);
		setSelectedFileGallery(processing?.galleryId as string);
	}, [processing]);

	useEffect(() => {
		if (socket) {
			socket.on(`eventOutfit-${user?.id}`, (data) => {
				setIsLoadingProcess(true);
				console.log("==============> data", data);
				setProcessing(data);
				if (data?.status === EnumStatusOutfit.SUCCESS) {
					handleActionStatus();
				}
				if (data?.status === EnumStatusOutfit.FAILURE) {
					handleActionStatus();
					SnackbarUtils.error("Please try again!");
				}
			});
			return () => {
				socket.off(`eventOutfit-${user?.id}`);
			};
		}
	}, [socket, user]);

	const renderImgStyle = useCallback(() => {
		const styles = {
			UPPER: upper,
			LOWER: {
				"full-length": lowerFull,
				"knee-length": lowerKnee,
				"mid-thigh": lowerMid,
			},
			DRESSES: {
				"full-length": dressFull,
				"knee-length": dressKnee,
				"mid-thigh": dressMid,
			},
		};

		if (valueCategory === "UPPER") return styles.UPPER;
		return styles[valueCategory]?.[valueSize];
	}, [valueCategory, valueSize]);

	return (
		<Box height={"100%"}>
			<Box
				height={"100px"}
				sx={{
					height: {
						xs: "120px",
						xl: "150px",
					},
				}}
				width={"100%"}
				display={"flex"}
				alignItems={"center"}>
				<StepperCus currentStep={currentStep} />
			</Box>
			<Box
				width={"90%"}
				margin={"auto"}
				sx={{
					height: { xs: "calc(100vh - 198px)", xl: "calc(100vh - 228px)" },
				}}>
				<Grid
					container
					sx={{
						maxWidth: {
							xs: "823px",
							xl: "1200px",
						},
						margin: "auto",
					}}
					spacing={2}
					height={"100%"}>
					<Grid item xs={4} height={"100%"}>
						<Box
							display={"flex"}
							flexDirection={"column"}
							gap={"20px"}
							height={"95%"}
							justifyContent={"center"}
							alignItems={"center"}>
							<BoxPhoto
								dropType={EDropType.BODY}
								title="Your Photo"
								description="Human photo will be showed here"
								initialImage={modelImage as string}
								active={currentStep === 0}
								currentStep={1}
								action={() => setCurrentStep(0)}
							/>
							<BoxPhoto
								dropType={EDropType.OUTFIT}
								title="Your Item"
								description="Item photo will be showed here"
								active={currentStep === 1}
								initialImage={imageClothes as string}
								currentStep={2}
								action={() => setCurrentStep(1)}
							/>
							<ButtonGenerate
								handelGenerate={handelGenerate}
								disabled={
									(!selectedFileGallery && !selectedFileModel) ||
									!selectedFileClothes ||
									isLoadingProcess
								}
								currentStep={3}
								active={currentStep === 2}
							/>
							<Box
								display={"flex"}
								width={"100%"}
								alignItems={"center"}
								gap={"5px"}
								justifyContent={"space-between"}>
								{arrButton.map((item) => (
									<ButtonAction
										icon={item.icon}
										text={item.title}
										action={item.action}
										key={item.title}
									/>
								))}
							</Box>
						</Box>
					</Grid>
					<Grid item xs={8}>
						<BoxResult
							initialImage={
								processing?.message === "OK" ? (processing.url as string) : ""
							}
							valueProcess={
								(processing?.currentStep && processing?.totalStep
									? processing?.currentStep / processing?.totalStep
									: 0) * 100
							}
							isLoading={isLoadingProcess}
							isStart={processing?.status === EnumStatusOutfit.STARTED}
							handleSwitchItem={handleSwitchItem}
							processing={processing}
							imgStyle={renderImgStyle()}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default StyleFit;
