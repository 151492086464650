/** @format */

import React from "react";
import { mainColor } from "theme";

import { Box, Grid } from "@mui/material";
import { Header, Sidebar } from "pages/admin/dashboard/components";
import {
	AuthCus,
	ForgotPass,
	Login,
	Register,
	Verify,
} from "components/AuthCus";
import { useLayout, useUser } from "hooks";
import { EModelAuth } from "types";
import ResetPass from "components/AuthCus/ResetPass";

interface MainLayoutProps {
	children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
	const { openModalAuth, setOpenModalAuth } = useLayout();
	const { isLogged } = useUser();
	return (
		<Box bgcolor={mainColor.primary} minHeight={"100vh"}>
			<Header />
			<Box
				width={"100%"}
				height={"100%"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}>
				<Box
					width={"100%"}
					height={"100%"}
					minHeight="calc(100vh - 76px)"
					component={"main"}>
					<Grid container height={"calc(100vh - 68px)"} overflow={"hidden"}>
						{/* ============== SIDEBAR ================ */}
						<Grid
							item
							xs={12}
							md={2}
							paddingY={"20px"}
							borderRight={`1px solid ${mainColor.borderColor}`}
							bgcolor={mainColor.white}
							sx={{
								paddingX: {
									xs: "10px",
									xl: "40px",
								},
								flex: 1,
							}}>
							<Sidebar />
						</Grid>
						{/* ============== END SIDEBAR ================ */}

						{/* ============== MAIN CONTENT ================ */}
						<Grid
							item
							xs={12}
							md={10}
							paddingBottom={"20px"}
							height={"100%"}
							bgcolor={mainColor.white}
							sx={{
								overflowY: "auto",
								overflowX: "hidden",
							}}>
							{children}
						</Grid>
						{/* ============== END MAIN CONTENT ================ */}
					</Grid>
				</Box>
			</Box>

			{/* ============== Modal Login ================= */}
			{openModalAuth === "login" && !isLogged && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={openModalAuth === EModelAuth.LOGIN}>
					<Login />
				</AuthCus>
			)}
			{/* ============== Modal Register =============== */}
			{openModalAuth === "register" && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={openModalAuth === EModelAuth.REGISTER}>
					<Register />
				</AuthCus>
			)}
			{openModalAuth === "verify" && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={openModalAuth === EModelAuth.VERIFY}>
					<Verify />
				</AuthCus>
			)}
			{openModalAuth === "forgot" && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={openModalAuth === EModelAuth.FORGOT}>
					<ForgotPass />
				</AuthCus>
			)}
			{openModalAuth === "resetPass" && (
				<AuthCus
					onclose={() => setOpenModalAuth(EModelAuth.DEFAULT)}
					isOpen={true}>
					<ResetPass />
				</AuthCus>
			)}
		</Box>
	);
};
