import { useMutation } from "@tanstack/react-query";
import { authService, userService } from "services";
import { EModelAuth, FormLogin, FormLoginSocial, FormRegister } from "types";
import { useKey } from "./useKey";
import { useUser } from "./useUser";
import { useLayout } from "./useLayout";
import SnackbarUtils from "utils/SnackbarUtils";
import { useTimer } from "./useTimer";

export const useAuthentication = () => {
	const { setKeySite, removeKeySite } = useKey();
	const { setUser, setIsLogged } = useUser();
	const { setOpenModalAuth } = useLayout();
	const { startTimer } = useTimer(60);

	const loginMutation = useMutation({
		mutationFn: (formData: FormLogin) => authService.login(formData),
		onSuccess: async ({ data }) => {
			console.log("Data", data);
			if (data?.statusCode === 200) {
				const { accessToken, refreshToken } = data.data;
				setKeySite({ authToken: accessToken, refreshToken: refreshToken });
				const rs = await userService.getProfile();
				console.log("rs get profile", rs);
				if (rs?.data) {
					setOpenModalAuth(EModelAuth.DEFAULT);
					setUser(rs.data?.data);
					setIsLogged(true);
				}
				return;
			}
			SnackbarUtils.warning(data?.message || "Login failed");
		},
		onError: (error) => {
			console.log("🚀 ~ SignIn ~ error:", error);
			SnackbarUtils.warning("Login failed!");
		},
	});

	const loginSocialMutation = useMutation({
		mutationFn: (formData: FormLoginSocial) =>
			authService.loginGoogle(formData),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				const { accessToken, refreshToken } = data.data;
				setKeySite({ authToken: accessToken, refreshToken: refreshToken });
				const rs = await userService.getProfile();
				if (rs?.data) {
					setOpenModalAuth(EModelAuth.DEFAULT);
					setUser(rs.data?.data);
					setIsLogged(true);
				}
				return;
			}
			SnackbarUtils.warning(data?.message || "Login failed");
		},
		onError: () => {
			SnackbarUtils.warning("Login failed!");
		},
	});

	const registerMutation = useMutation({
		mutationFn: (formData: FormRegister) => authService.register(formData),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				SnackbarUtils.success("Enter OTP is sent to your email");
				localStorage.setItem("email", data?.data?.email);
				setOpenModalAuth(EModelAuth.VERIFY);
				startTimer();
				return;
			}
			SnackbarUtils.warning(data?.message || "Register failed");
		},
		onError: (error) => {
			console.log("🚀 ~ SignIn ~ error:", error);
			SnackbarUtils.warning("Login failed!");
		},
	});

	const resetStore = () => {
		setIsLogged(false);
		removeKeySite();
		localStorage.clear();
		window.location.reload();
		return;
	};

	const logoutMutation = useMutation({
		mutationFn: () => userService.logout(),
		onSuccess: async ({ data }) => {
			console.log("Data logout", data);
			if (data.statusCode === 200) {
				resetStore();
			}
		},
		onError: (error) => {
			console.log("🚀 ~ SignIn ~ error:", error);
			SnackbarUtils.success("Logout success!");
			resetStore();
		},
	});

	return {
		loginMutation,
		registerMutation,
		logoutMutation,
		loginSocialMutation,
	};
};
