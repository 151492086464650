import { CloseOutlined } from "@mui/icons-material";
import { Box, Fade, IconButton, Modal } from "@mui/material";
import { Icons, up } from "assets";
import { ButtonCus } from "components/ButtonCus";
import { TextCus } from "components/TextCus";
import { useModel } from "hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fontSize, mainColor } from "theme";
import { IModelParams } from "types/outfit";
import SnackbarUtils from "utils/SnackbarUtils";

interface IModalProfile {
	open: boolean;
	onClose: () => void;
	handleRefetch: () => void;
}

const ModalUpload = ({ open, onClose, handleRefetch }: IModalProfile) => {
	const { generateModel, isPendingCreate } = useModel();
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);
	const [file, setFile] = useState<File | null>(null);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const url = URL.createObjectURL(file);
			setPreviewUrl(url);
			setFile(file);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};
	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleSaveModel = () => {
		const formData = new FormData();
		formData.append("name", "model");
		formData.append("typeModel", "MALE");
		formData.append("image", file as File);
		generateModel(formData as IModelParams, {
			onSuccess: async (res) => {
				if (res.data.statusCode === 200) {
					onClose();
					handleRefetch();
				}
			},
			onError: () => {
				SnackbarUtils.error("Please try again!");
			},
		});
	};

	const handleClear = useCallback(() => {
		setPreviewUrl(null);
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	}, []);

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					bgcolor={mainColor.white}
					flexDirection={"column"}
					paddingX={"30px"}
					paddingY={"20px"}
					sx={{
						maxWidth: "505px",
						maxHeight: "577px",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"764px"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"space-between"}>
						<TextCus fontWeight={"700"} fontSize={fontSize.sm}>
							Create New Model
						</TextCus>
						<IconButton onClick={onClose}>
							<CloseOutlined />
						</IconButton>
					</Box>

					{/* Box preview img */}
					<Box
						className="dash_linear"
						width={"100%"}
						height={"400px"}
						display={"flex"}
						flexDirection={"column"}
						gap={"25px"}
						justifyContent={"center"}
						position={"relative"}
						alignItems={"center"}
						border={"2px dashed #fff"}>
						<input
							type="file"
							ref={fileInputRef}
							style={{ display: "none" }}
							accept=".png, .jpg, .jpeg, .webp"
							onChange={handleFileChange}
						/>
						{previewUrl ? (
							<img
								src={previewUrl}
								alt="Preview"
								style={{
									maxWidth: "90%",
									maxHeight: "70%",
									opacity: isPreviewVisible ? 1 : 0,
									transition: "opacity 0.5s ease-in-out",
									borderRadius: "10px",
									margin: "0 auto",
								}}
							/>
						) : (
							<>
								<LazyLoadImage src={up} />
								<TextCus
									fontSize={fontSize.xs}
									fontWeight={"400"}
									color={mainColor.textGray}>
									Drop or upload your photo here
								</TextCus>
							</>
						)}
						{previewUrl ? (
							<Box
								onClick={handleClear}
								component={"div"}
								sx={{ cursor: "pointer" }}
								display={"flex"}
								alignItems={"center"}
								position={"absolute"}
								bgcolor={"#ccc"}
								borderRadius={"10px"}
								bottom={"10px"}
								right={"10px"}>
								<IconButton>{Icons.Delete()}</IconButton>
							</Box>
						) : (
							<ButtonCus
								sx={{ gap: "15px" }}
								onClick={() => {
									handleClick();
								}}>
								{Icons.Upload()}
								<TextCus fontSize={fontSize.xs} fontWeight={"600"}>
									Upload
								</TextCus>
							</ButtonCus>
						)}
					</Box>
					{/* Box preview */}

					<Box
						display={"flex"}
						alignItems={"center"}
						gap={"15px"}
						width={"100%"}>
						<ButtonCus
							sx={{ padding: "0px !important", width: "50%" }}
							onClick={onClose}>
							<Box
								bgcolor={"white"}
								width={"98%"}
								borderRadius={"10px"}
								height={"90%"}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}>
								<Box
									display={"flex"}
									alignItems={"center"}
									gap={"15px"}
									className="text_linear"
									fontSize={fontSize.xs}
									fontWeight={"600"}>
									Cancel
								</Box>
							</Box>
						</ButtonCus>
						<ButtonCus
							isLoading={isPendingCreate}
							disabled={!file}
							onClick={handleSaveModel}
							sx={{
								padding: 0,
								flex: 1,
								width: "50%",
							}}>
							<TextCus fontSize={fontSize.xs} fontWeight={"600"}>
								Create Model
							</TextCus>
						</ButtonCus>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalUpload;
