/** @format */

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { IMessage } from 'types';


type State = {
    default_url: string;
    isVideoPlaying: boolean
    messages : IMessage[]
};

type Actions = {
  setDefaultUrl: (v: string) => void;
  setStatusVideoPlaying : (v: boolean) => void
  setMessages : (msg: IMessage) => void
  setListMessage : (msgs: IMessage[]) => void
};

const initialState: State = {
    default_url: '',
    isVideoPlaying: false,
    messages: []
};

export const useStoreChat = create<State & Actions>()(
  devtools(
    set => ({
      ...initialState,
      setDefaultUrl: v => set({ default_url: v }),
      setStatusVideoPlaying: v => set({isVideoPlaying: v}),
      setMessages: (msg) =>
        set((state) => ({
          messages: [...(state.messages || []), msg],
        })),
      setListMessage: (msgs) => set({messages: msgs})
    }),
    {
      name: 'ChatStore',
    },
  ),
);
