import { Box } from "@mui/material";
import { Icons } from "assets";
import { TextCus } from "components";
import React from "react";
import { fontSize, mainColor } from "theme";

interface TTitleSection {
	title: string;
	action?: () => void;
}

const TitleSection = ({ title, action }: TTitleSection) => {
	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			width={"100%"}
			alignItems={"center"}>
			<TextCus
				color={mainColor.secondary}
				fontSize={fontSize.sm}
				fontWeight={700}>
				{title}
			</TextCus>
			{action && (
				<Box
					component="div"
					sx={{ cursor: "pointer" }}
					onClick={action}
					display={"flex"}
					alignItems={"center"}
					gap={"15px"}
					justifyContent={"center"}>
					<TextCus
						fontSize={"12px"}
						fontWeight={"500"}
						color={mainColor.textGray}>
						See all
					</TextCus>
					{Icons.Start("#666666")}
				</Box>
			)}
		</Box>
	);
};

export default TitleSection;
