/** @format */

import { useEffect } from 'react';

import { useSocket } from 'contexts';
import { TypeStatusDevice } from 'types';
import { userService } from 'services';

import { useKey } from './useKey';
import { useUser } from './useUser';
import SnackbarUtils from 'utils/SnackbarUtils';

export const useLoginOneDevice = (idDevice: string) => {
  const { socket } = useSocket();
  const { removeKey } = useKey();
  const { setIsLogged, isLogged } = useUser();

  const logout = async () => {
    await userService.logout();
    removeKey('deviceId');
    setIsLogged(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on(`eventLogin-${idDevice}`, data => {
        if (data?.status === TypeStatusDevice.OFFLINE && data.deviceId === idDevice && isLogged) {
          SnackbarUtils.warning('Your account is logged in elsewhere');
          logout();
        }
      });
      return () => {
        socket.off(`eventLogin-${idDevice}`);
      };
    }
  }, [socket, isLogged]);
};
