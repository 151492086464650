import { Box } from "@mui/material";
import { Icons } from "assets";
import { TextCus } from "components";
import React from "react";
import { fontSize } from "theme";

const Heading = ({ title }: { title: string }) => {
	return (
		<Box display={"flex"} alignItems={"center"} gap={"10px"}>
			{Icons.Gallery()}
			<TextCus fontSize={fontSize.xs} fontWeight={600} color={"#3B3899"}>
				{title}
			</TextCus>
		</Box>
	);
};

export default Heading;
