import { Box, Skeleton } from "@mui/material";
import React, { useCallback, useState } from "react";
import TitleSection from "./TitleSection";
import BoxTemplate from "./BoxTemplate";
import { TabCus } from "components/TabCus";
import { TParams } from "types";
import { useModel } from "hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Icons, illustration } from "assets";
import { ButtonCus, TextCus } from "components";
import { fontSize, mainColor } from "theme";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";

const arrTab = [
	{
		title: "Style Fit",
		value: "OUTFIT",
	},
	{
		title: "Gen Me",
		value: "FACE_SWAP",
	},
	{
		title: "Style Hair",
		value: "HAIR_SWAP",
	},
];

const YourGenerations = () => {
	const navigate = useNavigate();
	const { useGetGalleryList, deleteGalleryMutation } = useModel();
	const [paramsGallery, setParamsGallery] = useState<TParams>({
		page: 1,
		limit: 6,
		type: "OUTFIT",
	});
	const {
		gallery: dataGallery,
		refetch,
		isLoading,
	} = useGetGalleryList(paramsGallery);

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return (
				<Box
					width={"100%"}
					display={"flex"}
					gap={"10px"}
					alignItems={"center"}
					justifyContent={"space-between"}
					flexWrap={"wrap"}>
					{[...Array(3)].map((_, index) => {
						return (
							<Box
								display={"flex"}
								height={"350px"}
								gap={"10px"}
								flexDirection={"column"}
								key={index}>
								<Skeleton
									variant="rectangular"
									sx={{
										backgroundColor: "#ccc",
										borderRadius: "5px",
										height: "200px",
										width: "300px",
									}}
								/>
								<Skeleton
									variant="rectangular"
									sx={{
										backgroundColor: "#ccc",
										borderRadius: "5px",
										height: "20px",
										width: "200px",
									}}
								/>
								<Skeleton
									variant="rectangular"
									sx={{
										backgroundColor: "#ccc",
										borderRadius: "5px",
										height: "20px",
										width: "100px",
									}}
								/>
							</Box>
						);
					})}
				</Box>
			);
		} else {
			return (
				<Box
					width={"100%"}
					height={"328px"}
					display={"flex"}
					alignItems={"center"}
					gap={"15px"}
					justifyContent={"center"}
					flexDirection={"column"}>
					<LazyLoadImage src={illustration} alt="empty" />
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						flexDirection={"column"}>
						<TextCus color={mainColor.textMain}>
							Start by uploading a photo
						</TextCus>
						<TextCus color={mainColor.textGray}>
							Your generations will be shown here
						</TextCus>
					</Box>
					<ButtonCus
						sx={{ gap: "20px" }}
						onClick={() => navigate(APP_ROOT.STYLE_FIT)}>
						<TextCus fontWeight={"600"} fontSize={fontSize.xs}>
							Let&apos;s start
						</TextCus>
						{Icons.Start()}
					</ButtonCus>
				</Box>
			);
		}
	}, [isLoading]);

	const handleDelete = useCallback((id) => {
		deleteGalleryMutation.mutate(id, {
			onSuccess: () => {
				refetch();
			},
		});
	}, []);

	return (
		<Box className="wrap-content" mt={"200px"}>
			<TitleSection
				title="Your Recent Generations"
				action={() => navigate(APP_ROOT.HISTORY)}
			/>
			<Box my={"40px"}>
				<TabCus
					setValueTab={(v) => setParamsGallery({ ...paramsGallery, type: v })}
					arrTab={arrTab}
				/>
			</Box>
			{!dataGallery?.length || isLoading ? (
				renderDataLoading()
			) : (
				<Box
					mt={"20px"}
					display={"flex"}
					justifyContent={"space-start"}
					alignItems={"center"}
					gap={"20px"}
					rowGap={"25px"}
					flexWrap={"wrap"}>
					{dataGallery?.map((item) => {
						return (
							<BoxTemplate item={item} key={item.id} onDelete={handleDelete} />
						);
					})}
				</Box>
			)}
		</Box>
	);
};

export default YourGenerations;
