/** @format */

// /** @format */

import { useKey } from 'hooks';
import theme from 'theme/themes';
import { IThemeColor } from 'types';
import { KEY_CONTEXT } from 'utils';

export default () => {
  const { getKey } = useKey();
  const mode = getKey(KEY_CONTEXT.THEME_MODE) as IThemeColor;
  return {
    standard: {
      '.MuiInput-root': {
        '&::before': {
          borderBottom: `1px solid ${theme.palette.grey[300]} !important`,
        },
        '&::after': {
          borderBottom: `1px solid ${theme.palette.primary[mode]} !important`,
          transform: 'scaleX(0)',
        },
        '&.Mui-focused': {
          '&::after': {
            transform: 'scaleX(1)',
          },
        },
        '&:hover::before': {
          borderBottom: `1px solid ${theme.palette.grey[300]} !important`,
        },
      },
    },
    underline: {
      '.MuiInput-root': {
        '&::before': {
          borderBottom: 'none!important',
        },
        '&::after': {
          borderBottom: 'none!important',
        },
      },
    },
    outlined: {},
    filled: {},
    flexCenter: {
      width: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mt1: { mt: 1 },
    header: {
      justifyContent: 'space-between',
      height: 56,
      borderBottom: `1px solid ${theme.palette.primary[mode]}`,
      px: 2,
    },
    content: {
      display: 'grid',
      background: 'white',
      width: 880,
      minHeight: 520,
      borderRadius: 2,
      gridTemplateRows: 'repeat(1, auto) repeat(1, 1fr)',
    },
    bottom: {
      width: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      py: 2,
      borderTop: `1px solid ${theme.palette.primary[mode]}`,
    },
    textArea: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: `none`,
        },
      },
    },
    dateLabel: {
      '& label': {
        transform: 'translate(0,-1.5px) scale(0.75)',
      },
    },
    wrapLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    rMark: {
      color: 'red',
      ml: 0.5,
    },
  };
};
