/** @format */

import { Avatar } from "@mui/material";
import { avtDf } from "assets";
import { useUser } from "hooks";
import React from "react";

const AvatarCus = () => {
	const { user } = useUser();
	return (
		<Avatar
			src={user?.avatar && user?.avatar?.length > 0 ? user?.avatar : avtDf}
			sx={{
				display: { md: "flex" },
				width: "40px",
				height: "40px",
			}}
		/>
	);
};

export default AvatarCus;
