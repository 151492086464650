/** @format */

import { Box } from "@mui/material";
import { ButtonCus } from "components/ButtonCus";
import { TextCus } from "components/TextCus";
import React, { useEffect } from "react";
import { fontSize, mainColor } from "theme";
import { InputOutLine } from "components/InputCus";
import { logo } from "assets";
import { Controller, useForm, Resolver, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { dynamicSchema } from "utils";
import {
	EModelAuth,
	ErrorOtpForm,
	ErrorOtpMessage,
	ForgotForm,
	OTPForm,
	TypeSendMailOtp,
} from "types";
import { useLayout, useTimer } from "hooks";
import { useMutation } from "@tanstack/react-query";
import { authService, userService } from "services";
import SnackbarUtils from "utils/SnackbarUtils";

const Verify = () => {
	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isSubmitting },
	} = useForm<ForgotForm | OTPForm>({
		resolver: yupResolver(dynamicSchema(true)) as unknown as Resolver<
			ForgotForm | OTPForm
		>,
		defaultValues: {
			otp: "",
		},
	});

	const { setOpenModalAuth } = useLayout();
	const { seconds, isActive, startTimer } = useTimer(60);

	const verifyOtpMutation = useMutation({
		mutationFn: (formData: OTPForm) => authService.verifyEmail(formData.otp),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				SnackbarUtils.success(data?.message);
				setOpenModalAuth(EModelAuth.LOGIN);
				return;
			}
			SnackbarUtils.warning(data?.message || "Failed to send otp");
		},
		onError: () => {
			SnackbarUtils.warning("Change failed!");
		},
	});

	const sendOtpMutation = useMutation({
		mutationFn: (formData: ForgotForm) =>
			userService.getOtpViaMail(formData.email, TypeSendMailOtp.REGISTER),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				SnackbarUtils.success("Enter OTP is sent to your email");
				startTimer();
				return;
			}
			SnackbarUtils.warning(data?.message || "Failed to send otp");
		},
		onError: () => {
			SnackbarUtils.warning("Change failed!");
		},
	});

	const onSubmit: SubmitHandler<ForgotForm | OTPForm> = (data) => {
		verifyOtpMutation.mutate(data as OTPForm);
	};

	useEffect(() => {
		startTimer();
	}, []);

	return (
		<Box
			flex={1}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			gap={"100px"}
			flexDirection={"column"}
			width={"100%"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				width={"90%"}
				px={"15px"}
				flexDirection={"column"}
				gap={"20px"}>
				<img src={logo} width={"100px"} />
			</Box>

			<Box
				mt={2}
				flex={0.5}
				width={"90%"}
				px={"20px"}
				component="form"
				onSubmit={handleSubmit(onSubmit)}>
				<TextCus fontSize={"20px"} mb={2} fontWeight={"700"} color={"#333333"}>
					Please verify your email
				</TextCus>

				<Controller
					name="otp"
					control={control}
					defaultValue=""
					render={({ field }) => (
						<InputOutLine
							label="Enter OTP"
							value={`${field.value}`}
							setValue={field.onChange}
							error={!!(errors as ErrorOtpForm)?.otp}
							errorMessage={(errors as ErrorOtpMessage)?.otp?.message}
							fullWidth
							sx={{
								mt: 5,
								mb: 2.5,
							}}
						/>
					)}
				/>
				<ButtonCus
					sx={{
						mt: 5,
						backgroundColor: isValid
							? mainColor.secondary
							: mainColor.secondary,
					}}
					fullWidth
					type="submit"
					isLoading={verifyOtpMutation.isPending}
					disabled={!isValid || isSubmitting}>
					<TextCus
						color={mainColor.white}
						fontSize={fontSize.sm}
						fontWeight={"600"}>
						Verify
					</TextCus>
				</ButtonCus>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
				}}>
				<TextCus color={mainColor.textMain}>Didn’t get any email?</TextCus>
				<Box
					display={"flex"}
					ml={1}
					gap={"3px"}
					sx={{ cursor: "pointer" }}
					onClick={() => {
						if (!isActive) {
							sendOtpMutation.mutate({
								email: localStorage.getItem("email") + "",
							});
							startTimer();
						}
					}}>
					<TextCus useI18n variant="body1" color={mainColor.secondary}>
						Resend
					</TextCus>
					{isActive ? (
						<TextCus useI18n variant="body1" color={mainColor.textMain}>
							{` (${seconds}s)`}
						</TextCus>
					) : (
						""
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default Verify;
