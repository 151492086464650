import React, { useCallback } from "react";
import { Box, ImageList, Skeleton } from "@mui/material";
import { RuleFolder } from "@mui/icons-material";
import { mainColor } from "theme/default";
import { IModelType } from "types/outfit";
import { TextCus } from "components";
import ImageItem from "./ImageItem";
import { useModel } from "hooks";

export interface ImageListProps {
	images?: IModelType[];
	selectedImage?: string;
	setSelectedImage?: (image: IModelType) => void;
	isLoading?: boolean;
	refetch: () => void;
}

const ListImage: React.FC<ImageListProps> = ({
	images,
	selectedImage,
	setSelectedImage,
	isLoading,
	refetch,
}) => {
	const { deleteGalleryMutation } = useModel();
	const handleClick = useCallback((image: IModelType) => {
		setSelectedImage?.(image);
	}, []);

	const renderDataLoading = useCallback(() => {
		if (isLoading) {
			return (
				<>
					<Box
						width={"100%"}
						sx={{ aspectRatio: "9 / 6 auto" }}
						display={"flex"}
						gap={"10px"}
						alignItems={"center"}
						justifyItems={"space-between"}
						flexWrap={"wrap"}>
						{[...Array(2)].map((_, index) => {
							return (
								<Skeleton
									key={index}
									variant="rectangular"
									sx={{
										flex: 1,
										backgroundColor: "#ccc",
										borderRadius: "10px",
										aspectRatio: "9 / 4 auto",
										height: "100%",
									}}
								/>
							);
						})}
					</Box>
					<Box
						mt={"15px"}
						width={"100%"}
						sx={{ aspectRatio: "9 / 6 auto" }}
						display={"flex"}
						gap={"10px"}
						alignItems={"center"}
						justifyItems={"space-between"}
						flexWrap={"wrap"}>
						{[...Array(2)].map((_, index) => {
							return (
								<Skeleton
									key={index + 4}
									variant="rectangular"
									sx={{
										flex: 1,
										backgroundColor: "#ccc",
										borderRadius: "10px",
										aspectRatio: "9 / 4 auto",
										height: "100%",
									}}
								/>
							);
						})}
					</Box>
				</>
			);
		} else {
			return (
				<Box
					width={"100%"}
					height={"190px"}
					component={"div"}
					textAlign={"center"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					flexDirection={"column"}
					color={"white"}>
					<RuleFolder
						style={{
							width: "60px",
							height: "60px",
							color: "#ccc",
						}}
					/>
					<TextCus color={mainColor.textGray} fontStyle={"italic"}>
						This item currently has no data.
					</TextCus>
				</Box>
			);
		}
	}, [isLoading]);

	return (
		<>
			{!images?.length || isLoading ? (
				renderDataLoading()
			) : (
				<ImageList
					sx={{ width: "100%", height: "auto", overflow: "hidden" }}
					cols={2}
					rowHeight={"auto"}>
					{images.map((item) => (
						<ImageItem
							selectedImage={selectedImage}
							handleClick={handleClick}
							item={item}
							key={item?.id}
							actionDelete={(id: string) =>
								deleteGalleryMutation.mutate(id + "", {
									onSuccess: () => {
										refetch();
									},
								})
							}
						/>
					))}
				</ImageList>
			)}
		</>
	);
};

export default ListImage;
