import { Box } from "@mui/material";
import React from "react";
import theme from "theme/themes";
import { TextCus } from "components";
import { useWindowDimensions } from "hooks";

const LoadingPage = () => {
	const { height } = useWindowDimensions();
	return (
		<Box
			position={"absolute"}
			width={"100%"}
			height={height - 80}
			display={"flex"}
			flexDirection={"column"}
			gap={"60px"}
			alignItems={"center"}
			justifyContent={"center"}>
			<div className="loader_page">
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
			</div>
			<TextCus color={theme.palette.primary.main} fontWeight={"500"} fontStyle={"italic"}>
				Please wait, AI is initializing...
			</TextCus>
		</Box>
	);
};

export default LoadingPage;
