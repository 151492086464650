/** @format */

import { Box } from "@mui/material";
import { ButtonCus } from "components/ButtonCus";
import { TextCus } from "components/TextCus";
import React, { useState } from "react";
import { InputOutLine } from "components/InputCus";
import { Icons, logo } from "assets";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassSchema } from "utils";
import { EModelAuth, FormResetPassword } from "types";
import { useLayout, useUser } from "hooks";
import { useMutation } from "@tanstack/react-query";
import { authService } from "services";
import SnackbarUtils from "utils/SnackbarUtils";

const ResetPass = () => {
	const { uid } = useUser();
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors, isValid, isSubmitting },
	} = useForm<FormResetPassword>({
		resolver: yupResolver(resetPassSchema),
		defaultValues: {
			newPassword: "",
			confirmPassword: "",
		},
	});
	const [showPassword, setShowPassword] = useState(false);
	const [showCpw, setShowCpw] = useState(false);
	const { setOpenModalAuth } = useLayout();

	const resetPassMutation = useMutation({
		mutationFn: (formData: FormResetPassword) =>
			authService.changePassword({ newPassword: formData.newPassword, uid }),
		onSuccess: ({ data }) => {
			if (data?.statusCode === 200) {
				SnackbarUtils.success("Change password successful! Please login again");
				setOpenModalAuth(EModelAuth.LOGIN);
				return;
			}
			SnackbarUtils.warning(data?.message || "Change password failed");
		},
		onError: () => {
			SnackbarUtils.warning("Change password failed!");
		},
	});

	const onSubmit: SubmitHandler<FormResetPassword> = (data) => {
		resetPassMutation.mutate(data);
		reset();
	};
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleClickShowPcw = () => setShowCpw(!showCpw);

	return (
		<Box
			flex={1}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			gap={"100px"}
			flexDirection={"column"}
			width={"100%"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				width={"90%"}
				px={"15px"}
				flexDirection={"column"}
				gap={"20px"}>
				<img src={logo} width={"100px"} />
			</Box>
			<Box
				flex={0.5}
				width={"90%"}
				px={"15px"}
				component="form"
				onSubmit={handleSubmit(onSubmit)}>
				<Box>
					<TextCus
						fontSize={"20px"}
						mb={2}
						fontWeight={"700"}
						color={"#333333"}>
						Create a secure new password.
					</TextCus>
					<Controller
						name="newPassword"
						control={control}
						render={({ field }) => (
							<InputOutLine
								label="New password"
								{...field}
								value={field.value}
								setValue={field.onChange}
								fullWidth
								error={!!errors.newPassword}
								type={showPassword ? "text" : "password"}
								errorMessage={errors?.newPassword?.message}
								icon={<>{showPassword ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPassword}
							/>
						)}
					/>
				</Box>
				<Box mt={2}>
					<Controller
						name="confirmPassword"
						control={control}
						render={({ field }) => (
							<InputOutLine
								label="Confirm password"
								type={showCpw ? "text" : "password"}
								value={field.value}
								setValue={field.onChange}
								error={!!errors.confirmPassword}
								errorMessage={errors.confirmPassword?.message}
								fullWidth
								sx={{
									mb: 2.5,
								}}
								icon={<>{showCpw ? <Icons.Eye /> : <Icons.EyeHide />}</>}
								onClickIcon={handleClickShowPcw}
							/>
						)}
					/>

					<Box
						mt={5}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}>
						<ButtonCus
							title="Save password"
							LabelProps={{ variant: "bodyBold" }}
							useI18n
							sx={{ width: "100%" }}
							variant={"text"}
							type="submit"
							disabled={!isValid || isSubmitting}
							isLoading={resetPassMutation.isPending}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default ResetPass;
