import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useModel, useOutfitStore } from "hooks";
import { TParams } from "types";
import { EDropType } from "types/outfit";
import ListImage from "./ListImage";
import Heading from "./Heading";
import { TextCus } from "components";
import { Icons } from "assets";

export const Gallery: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileModel,
		setModelImage,
		selectedFileGallery,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();
	const { useGetGalleryList } = useModel();

	// ======================== STATE ========================
	const [paramsGallery] = useState<TParams>({
		page: 1,
		limit: 3,
		type: "OUTFIT",
	});
	const {
		gallery: dataGallery,
		isLoading: isLoadingGallery,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
	} = useGetGalleryList(paramsGallery);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Your Gallery" />
			<ListImage
				isLoading={isLoadingGallery}
				images={dataGallery}
				selectedImage={selectedFileGallery as string}
				setSelectedImage={(it) => {
					setSelectedFileModel(null);
					setModelImage(it.image as string);
					setSelectedFileGallery(it.id as string);
					setCurrentStep(1);
				}}
				dropType={EDropType.BODY}
			/>
			{hasNextPage && (
				<Box
					component={"div"}
					onClick={() => fetchNextPage()}
					width={"fit-content"}
					sx={{ cursor: "pointer" }}
					margin={"0 auto"}
					padding={"4px 10px"}
					borderRadius={"12px"}
					bgcolor={"rgba(149, 119, 217, 0.2)"}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					gap={"15px"}>
					<TextCus>{"See more"}</TextCus>
					{isFetchingNextPage ? (
						<CircularProgress size={15} />
					) : (
						<Icons.ArrowSelect />
					)}
				</Box>
			)}
		</Box>
	);
};
