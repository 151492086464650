import { TParams, TResponse } from "types";
import axiosInstance from "./axios";
import { IModelType } from "types/outfit";
import { END_POINT } from "utils";

export const clothesService = {
	getClothesExample: async (
		params: TParams,
	): Promise<TResponse<IModelType[]>> => {
		const { page, limit, ...restParams } = params;
		const queryParams = new URLSearchParams(
			restParams as Record<string, string>,
		).toString();
		const url = `${END_POINT.CLOTHES.GET_EXAMPLE}?page=${page}&limit=${limit}&${queryParams}`;
		const data = await axiosInstance.get(url);
		return data?.data;
	},

	getYourClothes: async (params: TParams): Promise<TResponse<IModelType[]>> => {
		const { page, limit } = params;
		const url = `${END_POINT.CLOTHES.YOUR_ITEMS}?page=${page}&limit=${limit}`;
		const data = await axiosInstance.get(url);
		return data?.data;
	},
};
