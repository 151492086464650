import React from "react";
import { Box, IconButton, ImageListItem } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IModelType } from "types/outfit";
import { mainColor } from "theme";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
import { Icons } from "assets";

interface IImageItem {
	handleClick?: (item: IModelType) => void;
	item: IModelType;
	selectedImage: string | undefined;
	actionDelete?: (id: string) => void;
}

const ImageItem = ({ item, selectedImage, actionDelete }: IImageItem) => {
	const navigate = useNavigate();
	return (
		<>
			<ImageListItem
				sx={{
					position: "relative",
					cursor: "pointer",
					transition: "all 0.25s ease-in-out",
					height: "auto",
					background:
						selectedImage === item?.id ? mainColor.bgLinear : "transparent",
					padding: "3px",
					borderRadius: "10px",
					":hover": {
						".box_history": {
							opacity: 1,
							pointerEvents: "unset",
						},
					},
				}}>
				<Box
					borderRadius={"10px"}
					display={"flex"}
					overflow={"hidden"}
					sx={{ aspectRatio: 3 / 4 }}
					position={"relative"}
					justifyContent={"center"}>
					<Box display={"flex"} justifyContent={"center"} position={"relative"}>
						<LazyLoadImage
							style={{
								borderRadius: "10px",
								width: "100%",
								height: "100%",
								transition: "all 0.25s ease-in-out",
								display: "block",
								objectFit: "cover",
							}}
							src={`${item?.image}?w=164&h=164&fit=crop&auto=format`}
							srcSet={`${item?.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
							alt={item?.name}
							loading="lazy"
							effect="blur"
						/>
						<Box
							position={"absolute"}
							top={0}
							left={0}
							width={"100%"}
							height={"20%"}
							component={"div"}
							display={"flex"}
							zIndex={9}
							alignItems={"center"}
							justifyContent={"center"}
							fontSize={"12px"}
							fontWeight={"500"}
							color={mainColor.white}
							sx={{
								background:
									"linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))",
							}}>
							{dayjs(item?.createdAt).format("DD MMM, hh:mm A") || ""}
						</Box>
					</Box>
					<Box
						position={"absolute"}
						top={0}
						width={"100%"}
						height={"100%"}
						sx={{
							background: "rgba(0, 0, 0, 0.3)",
							left:
								selectedImage === item?.id ? 0 : { xs: "-90px", md: "-190px" },
							transition: "all 0.25s ease-in-out",
							borderRadius: "10px",
						}}
					/>
					<Box
						width={"100%"}
						height={"100%"}
						component={"div"}
						onClick={() => navigate(`${APP_ROOT.HISTORY}?id=${item.id}`)}
						position={"absolute"}
						className="box_history"
						sx={{
							background: "rgba(0, 0, 0, 0.3)",
							transition: "all 0.25s ease-in-out",
							borderRadius: "10px",
							opacity: 0,
							pointerEvents: "none",
						}}>
						<Box
							onClick={() => actionDelete?.(item?.id + "")}
							component={"div"}
							sx={{ cursor: "pointer" }}
							display={"flex"}
							alignItems={"center"}
							position={"absolute"}
							bgcolor={"#FFFFFF"}
							borderRadius={"10px"}
							bottom={"10px"}
							right={"10px"}>
							<IconButton>{Icons.Delete()}</IconButton>
						</Box>
					</Box>
				</Box>
			</ImageListItem>
		</>
	);
};

export default ImageItem;
