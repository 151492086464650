import styled from "@emotion/styled";
import { Slider } from "@mui/material";
import React from "react";

const marks = [
	{
		value: 4,
		label: "04",
	},
	{
		value: 8,
		label: "08",
	},
	{
		value: 14,
		label: "14",
	},
	{
		value: 20,
		label: "20",
	},
	{
		value: 30,
		label: "30",
	},
];

const CustomSlider = styled(Slider)({
	color: "transparent",
	marginBottom: "0px",
	marginLeft: "5px",
	"& .MuiSlider-track": {
		background: "linear-gradient(90deg, #00c6ff, #ff8ce1)",
		height: 8,
		borderRadius: 4,
	},
	"& .MuiSlider-rail": {
		backgroundColor: "#E8E7FE",
		height: 8,
		opacity: 1,
		borderRadius: 4,
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		backgroundColor: "#ff00ff",
		border: "3px solid white",
		boxShadow: "unset !important",
		"&:hover": {
			boxShadow: "unset !important",
		},
		"&:active": {
			boxShadow: "unset !important",
		},
	},
	"& .MuiSlider-mark": {
		width: "8px",
		height: "8px",
		borderRadius: "100px",
		backgroundColor: "rgba(20, 4, 56, 0.15)",
		transform: "translate(-8px, -50%)",
	},
	"& .MuiSlider-markActive": {
		backgroundColor: "transparent",
	},
	"& .MuiSlider-markLabel": {
		top: "-20px",
		transform: "translateX(-65%)",
	},
});

interface ISliderCus {
	setVSlider: (v: number) => void;
	valueDefault: number;
}

const SliderCus = ({ setVSlider, valueDefault }: ISliderCus) => {
	const handleChange = (_: Event, newValue: number | number[]) => {
		if (typeof newValue === "number") {
			setVSlider(newValue);
		}
	};

	return (
		<CustomSlider
			sx={{ marginTop: "20px" }}
			defaultValue={30}
			step={null}
			marks={marks}
			value={valueDefault}
			min={4}
			onChange={handleChange}
			max={30}
		/>
	);
};

export default SliderCus;
