/** @format */
import React, { useState } from "react";
import { Box, Divider, Grid, Popover } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fontSize, mainColor } from "theme/default";
import { Link } from "react-router-dom";
import { logo } from "assets/images";
import { Icons } from "assets";
import {
	AvatarCus,
	BoxCredit,
	ButtonCus,
	ModalProfile,
	ModalUpdatePassword,
	TextCus,
} from "components";
import ItemMenu from "./ItemMenu";
import { useAuth } from "contexts";
import { useAuthentication, useLayout, useUser } from "hooks";
import { EModelAuth } from "types";

const Header = () => {
	const { isLogged } = useAuth();
	const { setOpenModalAuth } = useLayout();
	const { user } = useUser();
	const { logoutMutation } = useAuthentication();

	// =========== OPEN MODAL INFO =============
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;
	const [openProfile, setOpenProfile] = useState(false);
	const [openUpdatePass, setOpenUpdatePass] = useState(false);

	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			component={"nav"}
			height={"68px"}
			borderBottom={`1px solid ${mainColor.borderColor}`}
			bgcolor={mainColor.white}>
			<Grid
				container
				sx={{
					height: "100%",
					paddingX: {
						xs: "15px",
						md: "40px",
					},
				}}
				width={"100%"}>
				<Grid
					item
					xs={6}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"start"}>
					<Box>
						<Link to={"/"}>
							<LazyLoadImage width={80} src={logo} alt="logo" effect="blur" />
						</Link>
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					display={"flex"}
					gap={"20px"}
					sx={{
						gap: {
							xs: "0px",
							md: "20px",
						},
					}}
					alignItems={"center"}
					justifyContent={"flex-end"}>
					{isLogged ? (
						<>
							<BoxCredit />
							{/* <IconButton>{Icons.Notification()}</IconButton> */}
							<Box
								component={"button"}
								display={"flex"}
								alignItems={"center"}
								bgcolor={"transparent"}
								border={"none"}
								sx={{ cursor: "pointer", gap: { xs: "10px", md: "20px" } }}
								onClick={handleClick}
								aria-describedby={id}>
								<AvatarCus />
							</Box>
						</>
					) : (
						<>
							<ButtonCus
								sx={{ padding: "0px !important" }}
								onClick={() => setOpenModalAuth(EModelAuth.LOGIN)}>
								<Box
									bgcolor={"white"}
									width={"97%"}
									borderRadius={"10px"}
									height={"90%"}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}>
									<Box
										className="text_linear"
										fontSize={fontSize.xs}
										fontWeight={"600"}>
										Login
									</Box>
								</Box>
							</ButtonCus>

							<ButtonCus onClick={() => setOpenModalAuth(EModelAuth.REGISTER)}>
								Register
							</ButtonCus>
						</>
					)}

					{/* ========== POPUP MENU ============ */}
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						sx={{
							top: "10px",
							overflow: "hidden",
							borderRadius: "20px",
						}}>
						<Box
							width={"242px"}
							paddingX={"15px"}
							paddingY={"20px"}
							display={"flex"}
							overflow={"hidden"}
							bgcolor={mainColor.primary}
							flexDirection={"column"}
							gap={"10px"}
							borderRadius={"20px !important"}>
							<Box alignItems={"center"} gap={"10px"} display={"flex"}>
								<AvatarCus />
								<Box display={"flex"} flexDirection={"column"}>
									<TextCus
										fontSize={fontSize.sm}
										color={mainColor.textMain}
										fontWeight={"600"}>
										{user?.userName}
									</TextCus>
									<TextCus
										fontSize={fontSize.xs}
										color={mainColor.textMain}
										width={"70%"}
										fontWeight={"400"}
										className="res_text">
										{user?.email}
									</TextCus>
								</Box>
							</Box>
							<Divider />
							<ItemMenu
								icon={Icons.Home()}
								title={"Edit profile"}
								onAction={() => setOpenProfile(true)}
							/>
							{/* <ItemMenu
								isMgTop
								icon={Icons.Setting()}
								title={"Setting"}
								onAction={() => {
									console.log("Setting");
								}}
							/> */}
							<Divider />
							{/* <ItemMenu
								icon={Icons.Help()}
								title={"Help center"}
								onAction={() => {
									console.log("Help center");
								}}
							/> */}
							<ItemMenu
								isMgTop
								icon={Icons.Logout()}
								title={"Logout"}
								onAction={() => {
									logoutMutation.mutate();
									handleClose();
								}}
							/>
						</Box>
					</Popover>
				</Grid>
			</Grid>
			{openProfile && (
				<ModalProfile
					open={openProfile}
					onClose={() => setOpenProfile(false)}
					onOpenUpdatePass={() => setOpenUpdatePass(true)}
				/>
			)}
			{openUpdatePass && (
				<ModalUpdatePassword
					open={openUpdatePass}
					onClose={() => setOpenUpdatePass(false)}
				/>
			)}
		</Box>
	);
};

export default Header;
