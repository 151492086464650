/** @format */

import React from "react";
import { Box, Grid } from "@mui/material";
import { fontSize, mainColor } from "theme/default";
import { TextCus } from "components";
import { Icons } from "assets";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";

const Header = () => {
	const navigate = useNavigate();
	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			component={"nav"}
			height={"68px"}
			borderBottom={`1px solid ${mainColor.borderColor}`}
			bgcolor={mainColor.white}>
			<Grid
				container
				sx={{
					height: "100%",
					paddingX: {
						xs: "15px",
						xl: "40px",
					},
				}}
				width={"100%"}>
				<Grid
					item
					xs={6}
					display={"flex"}
					alignItems={"center"}
					gap={"20px"}
					justifyContent={"start"}>
					<Box
						component={"div"}
						onClick={() => navigate(APP_ROOT.DASHBOARD)}
						sx={{
							cursor: "pointer",
							display: "flex",
							alignItems: "center",
							gap: "20px",
							textDecoration: "none",
						}}>
						<Box
							width={"36px"}
							height={"36px"}
							borderRadius={"12px"}
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							bgcolor={mainColor.bgButton}>
							{Icons.Back()}
						</Box>
						<TextCus
							color={mainColor.secondary}
							sx={{
								fontSize: {
									xs: fontSize.sm,
									md: fontSize.sm,
								},
							}}
							fontWeight={"700"}>
							YOUR GENERATIONS
						</TextCus>
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					display={"flex"}
					gap={"20px"}
					alignItems={"center"}
					justifyContent={"flex-end"}></Grid>
			</Grid>
		</Box>
	);
};

export default Header;
