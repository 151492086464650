import { Box } from "@mui/material";
import React, { useState } from "react";
import TitleSection from "./TitleSection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useModel, useUser } from "hooks";
import { TParams } from "types";
import { useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";

const YourModels = () => {
	const navigate = useNavigate();
	const { isLogged } = useUser();
	const { useGetModelRandom } = useModel();
	const [paramsModels] = useState<TParams>({
		page: 1,
		limit: 6,
	});
	const { models: dataModel } = useGetModelRandom(paramsModels);

	return (
		<Box className="wrap-content" mt={isLogged ? "70px" : "150px"}>
			<TitleSection
				title="Your Preset Models"
				action={() => navigate(APP_ROOT.MODELS)}
			/>
			<Box
				mt={"20px"}
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				gap={"20px"}
				flexWrap={"wrap"}>
				{dataModel.map((item, index) => {
					return (
						<Box
							width={"133px"}
							height={"133px"}
							borderRadius={"100%"}
							border="2px solid #ccc"
							overflow={"hidden"}
							sx={{ cursor: "pointer" }}
							key={`${item?.id || "" + index}`}>
							<LazyLoadImage
								style={{
									display: "block",
									objectFit: "contain",
									width: "100%",
								}}
								src={`${item?.image}?w=164&h=164&fit=crop&auto=format`}
								srcSet={`${item?.image}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
								alt="model"
							/>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default YourModels;
