/** @format */

import { Box } from "@mui/material";
import { banner } from "assets";
import { TextCus } from "components";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { mainColor } from "theme";

const Banner = () => {
	return (
		<Box
			width={"100%"}
			position={"relative"}
			display={"flex"}
			borderRadius={"24px"}>
			<LazyLoadImage
				style={{ display: "block", objectFit: "contain", width: "100%" }}
				src={banner}
				alt="banner"
			/>
			<Box
				display={"flex"}
				position={"absolute"}
				width={"100%"}
				height={"100%"}
				left={"0"}
				justifyContent={"center"}
				alignItems={"center"}
				gap={"20px"}
				flexDirection={"column"}>
				<TextCus
					sx={{
						fontSize: {
							xs: "18px",
							md: "40px",
						},
					}}
					fontWeight={"700"}
					color={mainColor.primary}>
					Choose Your Model
				</TextCus>
			</Box>
		</Box>
	);
};

export default Banner;
