import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useModel, useOutfitStore } from "hooks";
import { TParams } from "types";
import { EDropType, IModelType } from "types/outfit";
import ListImage from "./ListImage";
import Heading from "./Heading";
import { SelectCus, TextCus } from "components";
import { arrGender } from "utils";
import { Icons } from "assets";

export const ModelBody: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileModel,
		setModelImage,
		selectedFileModel,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();
	const { useGetModels } = useModel();

	// ======================== STATE ========================
	const [paramsModels, setParamsModels] = useState<TParams>({
		page: 1,
		limit: 3,
		typeModel: "FEMALE",
	});

	const {
		models: dataModel,
		isLoading: isLoadingFemale,
		fetchNextPage,
		isFetchingNextPage,
	} = useGetModels(paramsModels);

	const handleChange = (event) => {
		setParamsModels({
			...paramsModels,
			typeModel: event.target.value,
		});
	};

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Heading title="Preset Models" />
			<SelectCus
				width="120px"
				onChange={handleChange}
				options={arrGender}
				value={paramsModels?.typeModel + ""}
			/>
			<ListImage
				isLoading={isLoadingFemale}
				images={dataModel as IModelType[] | undefined}
				selectedImage={selectedFileModel as string}
				setSelectedImage={(it) => {
					setSelectedFileModel(it.id as string);
					setModelImage(it.image as string);
					setSelectedFileGallery(null);
					setCurrentStep(1);
				}}
				dropType={EDropType.BODY}
			/>
			<Box
				component={"div"}
				onClick={() => fetchNextPage()}
				width={"fit-content"}
				sx={{ cursor: "pointer" }}
				margin={"0 auto"}
				padding={"4px 10px"}
				borderRadius={"12px"}
				bgcolor={"rgba(149, 119, 217, 0.2)"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				gap={"15px"}>
				<TextCus>{"See more"}</TextCus>
				{isFetchingNextPage ? (
					<CircularProgress size={15} />
				) : (
					<Icons.ArrowSelect />
				)}
			</Box>
		</Box>
	);
};
