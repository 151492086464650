import React from "react";
import { Stepper, Step, StepLabel, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StepLabelStyled = styled(StepLabel)(() => ({
	"& .MuiSvgIcon-root.MuiStepIcon-root.Mui-active": {
		color: "#9577D9",
		text: {
			fontSize: "12px",
			fontWeight: "600",
		},
	},
	"& .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed": {
		color: "#34C759",
	},
	"& .MuiStepLabel-iconContainer.Mui-disabled": {
		".MuiSvgIcon-fontSizeMedium.MuiStepIcon-root": {
			color: "white",
			text: {
				fill: "black",
				fontSize: "12px",
				fontWeight: "600",
			},
		},
	},
	"& .MuiStepLabel-label": {
		fontWeight: "bold",
		transition: "color 0.3s, transform 0.3s",
		"& .MuiTypography-root": {
			fontSize: "14px",
		},
	},
}));

// Main component
const StepperCus = ({ currentStep }: { currentStep: number }) => {
	const steps = [
		"Upload Your Photos",
		"Upload Your Items",
		"Generate",
		"See Your Result",
	];

	return (
		<Stepper
			alternativeLabel
			activeStep={currentStep}
			sx={{
				width: "100%",
				"& .MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel": {
					top: "9px",
					left: "calc(-50% + 12px)",
					right: "calc(50% + 12px)",
				},
				"& .MuiStepConnector-line.MuiStepConnector-lineHorizontal": {
					border: "3px solid #E8E7FE",
				},
			}}>
			{steps.map((label, index) => (
				<Step key={label}>
					<StepLabelStyled>
						<Typography
							sx={{
								color: "#5B3E9B",
								fontWeight: index === currentStep ? "700" : "400",
							}}>
							{label}
						</Typography>
					</StepLabelStyled>
				</Step>
			))}
		</Stepper>
	);
};

export default StepperCus;
