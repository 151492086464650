/** @format */

export default {
	boxContainer: {
		display: "flex",
		borderRadius: 5,
		justifyContent: "space-between",
		overflow: "hidden",
	},
	boxMain: {
		backgroundColor: "white",
		flex: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-around",
		flexDirection: "column",
		overflowY: "auto",
		paddingY: "20px",
	},
	content: { mt: "26px", mb: 4, position: "relative" },
	// img: { width: 675, height: 675 },
	min230: { minWidth: 200, height: "60px" },
};
