import { useMutation } from "@tanstack/react-query";
import { aiService } from "services";
import { IOutfitParams } from "types/outfit";

export const useAITools = () => {
	const { mutate: generateOutfit, isPending } = useMutation({
		mutationFn: (form: IOutfitParams) => aiService.outfitTools(form),
	});

	return {
		generateOutfit,
		isPending,
	};
};
