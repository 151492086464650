/** @format */
import React, { useCallback, useState } from "react";
import { Box } from "@mui/material";
import { SelectCus, TabCus, TextCus } from "components";
import { fontSize, mainColor } from "theme";
import BoxUpload from "./BoxUpload";
import { ModelBody } from "./ModelBody";
import { arrTab, arrTabItems, CATEGORY, SIZE_BODY } from "utils";
import { Gallery } from "./Gallery";
import { useOutfitStore } from "hooks";
import { ModelItems } from "./ModelItems";
import { YourBody } from "./YourBody";
import { YourItems } from "./YourItems";

const Sidebar = () => {
	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		currentStep,
		setSelectedFileClothes,
		valueCategory,
		valueSize,
		setValueCategory,
		setImageClothes,
		setValueSize,
	} = useOutfitStore();
	const [itemTab, setItemTab] = useState("UPLOAD");

	const renderSidebar = useCallback(() => {
		switch (currentStep) {
			case 0:
				return (
					<Box flex={1}>
						<Box height={"50px"}>
							<TabCus arrTab={arrTab} setValueTab={(v) => setItemTab(v)} />
						</Box>
						<Box sx={{ height: "calc(100vh - 229px)" }}>
							{itemTab === "UPLOAD" ? (
								<Box padding={"15px"} height={"100%"}>
									<BoxUpload
										onFileSelect={(file: File) => {
											setSelectedFileGallery(null);
											setSelectedFileModel(file);
											setModelImage(URL.createObjectURL(file));
										}}
										onClear={() => setModelImage("")}
									/>
								</Box>
							) : (
								<Box
									display={"flex"}
									gap={"20px"}
									padding={"15px"}
									flexDirection={"column"}
									height={"100%"}
									sx={{ overflowY: "auto" }}>
									<Gallery />
									<ModelBody />
									<YourBody />
								</Box>
							)}
						</Box>
					</Box>
				);
			default:
				return (
					<Box flex={1}>
						<Box height={"50px"}>
							<TabCus arrTab={arrTabItems} setValueTab={(v) => setItemTab(v)} />
						</Box>
						<Box sx={{ height: "calc(100vh - 229px)" }}>
							{itemTab === "UPLOAD" ? (
								<Box
									display={"flex"}
									justifyContent={"space-between"}
									flexDirection={"column"}
									padding={"15px"}
									height={"100%"}>
									<Box
										display={"flex"}
										alignItems={"center"}
										gap={"15px"}
										justifyContent={"space-between"}>
										<SelectCus
											onChange={(e) => setValueCategory(e.target.value)}
											options={CATEGORY}
											value={valueCategory}
											label="Category"
										/>
										<SelectCus
											onChange={(e) => setValueSize(e.target.value)}
											options={SIZE_BODY}
											value={valueSize}
											label="Length"
										/>
									</Box>
									<Box sx={{ height: "calc(100% - 80px)" }}>
										<BoxUpload
											onFileSelect={(file) => {
												setSelectedFileClothes(file);
												setImageClothes(URL.createObjectURL(file));
											}}
											onClear={() => setImageClothes("")}
										/>
									</Box>
								</Box>
							) : (
								<Box
									display={"flex"}
									gap={"20px"}
									padding={"15px"}
									flexDirection={"column"}
									height={"100%"}
									sx={{ overflowY: "auto" }}>
									<ModelItems />
									<YourItems />
								</Box>
							)}
						</Box>
					</Box>
				);
		}
	}, [currentStep, itemTab, valueCategory, valueSize]);

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"space-between"}
			position={"relative"}
			height={"100%"}>
			<Box
				display={"flex"}
				paddingX={"15px"}
				flexDirection={"column"}
				gap={"2px"}
				justifyContent={"center"}
				height={"98px"}>
				<TextCus
					fontSize={fontSize.sm}
					color={mainColor.secondary}
					fontWeight={"700"}>
					YOUR STATION
				</TextCus>
				<TextCus fontSize={"12px"} color={"#FF52F0"} fontWeight={"500"}>
					Upload your photos or choose one model in “Use model” tab
				</TextCus>
			</Box>

			{renderSidebar()}
			{/* 
			<Box
				gap={"20px"}
				display={"flex"}
				paddingX={"20px"}
				flexDirection={"column"}
				justifyContent={"center"}
				borderTop={"1px solid #ccc"}
				height={"141px"}>
				<Box>
					<TextCus
						mb={"3px"}
						fontSize={fontSize.xs}
						color={mainColor.textMain}
						fontWeight={"600"}>
						Quality Level
					</TextCus>
					<TextCus
						fontSize={"12px"}
						color={mainColor.textGray}
						fontWeight={"500"}>
						Set the detail level for the image result
					</TextCus>
				</Box>
				<SliderCus valueDefault={denoiseSteps} setVSlider={setDenoiseSteps} />
			</Box> */}
		</Box>
	);
};

export default Sidebar;
