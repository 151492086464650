/** @format */
import React, { useCallback } from "react";
import { Box, Grid } from "@mui/material";
import { StepperCus } from "components";
import { EDropType } from "types/outfit";
import { useOutfitStore } from "hooks";
import { BoxPhoto, BoxResult } from "../stylefit/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "utils";
import { modelService } from "services";
import { APP_ROOT } from "utils/routes";

const History = () => {
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const navigate = useNavigate();
	const { data, isLoading } = useQuery({
		queryKey: [QueryKeys.GET_DETAIL_GALLERY, id],
		queryFn: () => modelService.detailGallery(id + ""),
		enabled: !!id,
	});
	const {
		setCurrentStep,
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
	} = useOutfitStore();

	const handleSwitchItem = useCallback(() => {
		setModelImage(data?.data?.image as string);
		setSelectedFileGallery(data?.data?.id as string);
		setCurrentStep(0);
		setSelectedFileModel(null);
		navigate(APP_ROOT.STYLE_FIT);
	}, [data]);

	return (
		<Box height={"100%"}>
			<Box
				height={"100px"}
				sx={{
					height: {
						xs: "120px",
						xl: "150px",
					},
				}}
				width={"100%"}
				display={"flex"}
				alignItems={"center"}>
				<StepperCus currentStep={5} />
			</Box>
			<Box
				width={"90%"}
				margin={"auto"}
				sx={{
					height: { xs: "calc(100vh - 198px)", xl: "calc(100vh - 228px)" },
				}}>
				<Grid
					container
					sx={{
						maxWidth: {
							xs: "823px",
							xl: "1200px",
						},
						margin: "auto",
					}}
					spacing={2}
					height={"100%"}>
					<Grid item xs={4} height={"100%"}>
						<Box
							display={"flex"}
							flexDirection={"column"}
							gap={"20px"}
							height={"95%"}
							justifyContent={"flex-start"}
							alignItems={"center"}>
							<BoxPhoto
								dropType={EDropType.BODY}
								title="Your Photo"
								description="Human photo will be showed here"
								initialImage={data?.data.modelImage as string}
								active={true}
								currentStep={1}
							/>
							<BoxPhoto
								dropType={EDropType.OUTFIT}
								title="Your Item"
								description="Item photo will be showed here"
								active={true}
								initialImage={data?.data.clothesImage as string}
								currentStep={2}
							/>
						</Box>
					</Grid>
					<Grid item xs={8}>
						<BoxResult
							initialImage={data?.data.image as string}
							valueProcess={0}
							isLoading={isLoading}
							isLoadingHistory
							isStart={false}
							handleSwitchItem={handleSwitchItem}
							processing={""}
						/>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default History;
